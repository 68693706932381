import React, { useRef, useEffect } from "react";
interface ModalProps {
  onClose: () => void;
  children?: React.ReactNode;
  subject?: string;
}

const Modal: React.FC<ModalProps> = (props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  return (
    <>
      <div className="bg-black opacity-40 w-full h-full fixed top-0 left-0 z-50" onClick={props.onClose} />
      <div
        ref={modalRef}
        className="bg-white drop-shadow z-50 fixed lg:w-1/3 min-w-80 max-w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded">
        {props.subject && <div className="border-b lg:px-10 px-5 py-5 font-semibold lg:text-2xl text-lg">{props.subject}</div>}
        <div className="lg:px-10 px-5 lg:py-10 p-5 lg:max-h-full max-h-96 lg:overflow-y-visible overflow-y-scroll">{props.children}</div>
        <div className="absolute lg:top-3 lg:right-3 top-2 right-2" onClick={props.onClose}>
          <span className="material-symbols-outlined text-neutral-400 hover:text-black cursor-pointer">close</span>
        </div>
      </div>
    </>
  );
};

export default Modal;
