import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AlertCard from "../../Molecules/AlertCard";
import { connect } from "react-redux";
import AppState from "../../../models/AppState";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "react-query";
import { getAlertMessages } from "../../../util/ApiCalls";
import { useErrorHandling } from "../../Atoms/useErrorHandling";

interface AlertsModalProps {
  isOpen: any;
  setIsOpen: any;
  dashboard: any;
  user: any;
}

const AlertsModal = (props: AlertsModalProps) => {
  const { t } = useTranslation("translation");
  const { handleQueryError } = useErrorHandling();
  const [offset] = useState(0);
  const [limit] = useState(5);

  const { data: messages, isLoading: messagesLoading } = useQuery(
    ["AlertMessages", props.user.id, offset, limit],
    ({ signal }) => getAlertMessages(props.user.id, offset, limit, signal),
    {
      onError: handleQueryError,
    }
  );

  return (
    <>
      <div className="bg-tranparent w-full h-full fixed top-0 left-0 z-50" onClick={() => props.setIsOpen(false)} />

      <div className="bg-white lg:drop-shadow-lg lg:border z-50 absolute lg:w-96 w-full lg:h-auto h-screen lg:right-0 lg:left-auto left-0 lg:top-12 top-0 rounded text-black">
        <div className="border-b lg:px-5 p-3 flex gap-5">
          <p className="font-semibold text-xl w-full">{t("View alerts") as string}</p>
          {/*
          <SelectRobotAlert onClick={() => props.setIsOpen(false)} />
          */}
          <div onClick={() => props.setIsOpen(false)}>
            <span className="material-symbols-outlined text-2xl h-fit text-neutral-400 hover:text-black cursor-pointer">close</span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-3 px-5 py-5 lg:overflow-y-visible overflow-y-scroll">
          {messagesLoading ? (
            <>
              <div className="border p-2 rounded bg-white h-20 overflow-hidden animate-pulse">
                <p className="text-base text-black font-medium bg-neutral-200 h-4 w-5/6"></p>
                <p className="text-sm text-neutral-700 mt-3 bg-neutral-200 h-8"></p>
              </div>
              <div className="border p-2 rounded bg-white h-20 overflow-hidden animate-pulse">
                <p className="text-base text-black font-medium bg-neutral-200 h-4 w-5/6"></p>
                <p className="text-sm text-neutral-700 mt-3 bg-neutral-200 h-8"></p>
              </div>
              <div className="border p-2 rounded bg-white h-20 overflow-hidden animate-pulse">
                <p className="text-base text-black font-medium bg-neutral-200 h-4 w-5/6"></p>
                <p className="text-sm text-neutral-700 mt-3 bg-neutral-200 h-8"></p>
              </div>
              <div className="border p-2 rounded bg-white h-20 overflow-hidden animate-pulse">
                <p className="text-base text-black font-medium bg-neutral-200 h-4 w-5/6"></p>
                <p className="text-sm text-neutral-700 mt-3 bg-neutral-200 h-8"></p>
              </div>
              <div className="border p-2 rounded bg-white h-20 overflow-hidden animate-pulse">
                <p className="text-base text-black font-medium bg-neutral-200 h-4 w-5/6"></p>
                <p className="text-sm text-neutral-700 mt-3 bg-neutral-200 h-8"></p>
              </div>
            </>
          ) : (
            messages?.map((message: any, index: number) => (
              <AlertCard
                key={index}
                date={new Date(message.created_time * 1000)}
                house={{ name: message.house_name, id: message.house_id }}
                message={message.alert_message}
                onClose={() => props.setIsOpen(false)}
              />
            ))
          )}

          {(!messages || messages.length === 0) && <p>{t("There are no alerts available right now")}</p>}

          {messages && messages.length > 0 && (
            <div className="w-fit mx-auto flex gap-2 mt-2 lg:h-auto h-full">
              <RouterLink
                to="/alert/list"
                className="px-2 py-1 bg-white text-black border-black hover:bg-neutral-100 border-2 rounded text-center text-sm h-fit">
                {t("View all")}
              </RouterLink>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    dashboard: state.userData.dashboard,
    user: state.userData.identity,
  };
};

export default connect(mapStateToProps)(AlertsModal);
