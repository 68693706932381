import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { appStore } from "./store";
import App from "./containers/App";
import { Provider } from "react-redux";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
//import { ReactQueryDevtools } from "react-query/devtools";
import "./i18n";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
    },
  },
});

const renderApp = (Component: any) => {
  const container: any = document.getElementById("root");
  const root = createRoot(container);

  root.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={appStore}>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
};

renderApp(App);
