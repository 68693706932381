const identities = {
  name: "",
  role: "",
  email: "",
  id: "",
  lang: "",
  user_role: "",
};

const dashboard = [
  {
    id: "",
    name: "",
    totalAlerts: 0,
    houses: [
      {
        id: "",
        name: "",
        roundStart: "",
        roundStop: "",
        roundId: "",
        robots: [
          {
            id: "",
            name: "",
            status: "",
            totalAlerts: 0,
            operationalState: "",
            onCharger: "",
            errorMsg: "",
            estimatedRecharge: "",
            robotType: "cb",
          },
        ],
      },
    ],
  },
];

const filters = {
  search: "",
  sortByAlerts: false,
  sortByProdCycle: false,
  type: "all",
};

const farmDashBoardPicked = null;
const redirectUrl = "/admin";

const robots = {
  total: 0,
};

const farms = {
  total: 0,
};

const houses = {
  total: 0,
};

const alerts = {
  total: 0,
};

const rounds = {
  total: 0,
};

const users = {
  total: 0,
};

const isSessionActive: boolean | null = null;

const userInitData = {
  identity: identities,
  robots,
  houses,
  farms,
  alerts,
  rounds,
  users,
  dashboard,
  farmDashBoardPicked,
  filters,
  isSessionActive,
  redirectUrl,
};

const initializeState = {
  form: {},
  userData: userInitData,
};

export default initializeState;
