import axios from "axios";

export function getReportsTabs(signal: any) {
  const url = `/api/v2/report/get-tabs`;
  const req = axios.get(url, { signal });
  return req;
}

export function getReportsParams(signal: any) {
  const url = `/api/v2/report/get-report-form-params`;
  const req = axios.get(url, { signal });
  return req;
}

export const saveTabData = () => {
  return `/api/v2/report/save-tab-data`;
};
