import { Navigate, useNavigate } from "react-router-dom";
import AppState from "../../../models/AppState";
import { connect } from "react-redux";
import React from "react";
interface UnProtectedRoutesProps {
  children;
  isSignedIn;
  redirectUrl: string;
  identity: any;
}
const UnProtectedRoutes = (props: UnProtectedRoutesProps) => {
  const navigate = useNavigate();
  if (props.isSignedIn) {
    if (parseInt(props.identity.user_role) === 3) {
      return <Navigate to={"/admin"} replace />;
    } else {
      if (props.redirectUrl === "/error") navigate("/admin");
      else return <Navigate to={props.redirectUrl} replace />;
    }
  } else {
    return props.children;
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    identity: state.userData.identity,
    isSignedIn: state.userData.isSessionActive,
    redirectUrl: state.userData.redirectUrl,
  };
};

export default connect(mapStateToProps)(UnProtectedRoutes);
