import React from "react";
import { connect } from "react-redux";
import AppState from "../models/AppState";
import Header from "../components/Organisms/Header";
import Footer from "../components/Organisms/Footer";
import HelpButton from "../components/Molecules/HelpButton";
import { useLocation } from "react-router-dom";
import HeaderAdmin from "../components/Organisms/HeaderAdmin";

interface LayoutProps {
  userData;
  pageType: string;
  children;
}

const Layout = (props: LayoutProps) => {
  const location = useLocation();
  const routerParams = location.pathname.split("/");

  return (
    <>
      {props.pageType !== "Login" ? (
        <>
          {routerParams[0] === "admin" || routerParams[1] === "admin" || parseInt(props.userData.identity.user_role) === 3 ? (
            <>
              <HeaderAdmin userData={props.userData} />
              <div className="flex pb-20">
                <div className="w-full min-h-body">{props.children}</div>
              </div>
              <div className="lg:block hidden">
                <Footer />
              </div>
            </>
          ) : (
            <div className="md:mb-0 ">
              <Header
                userData={props.userData}
                houses={props.userData.houses.total}
                farms={props.userData.farms.total}
                robots={props.userData.robots.total}
                alerts={0}
              />
              <div className="flex pb-20">
                <div className="w-full min-h-body">{props.children}</div>
              </div>
              <div className="lg:block hidden">
                <Footer />
              </div>

              <HelpButton />
            </div>
          )}
        </>
      ) : (
        <>
          {props.children}
          <div className="block lg:bg-white bg-neutral-900 lg-p-0 p-5">
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps)(Layout);
