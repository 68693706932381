import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../../Layout";
import ApiError from "../../../Pages/Error/ApiError";

interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = (props) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleOnError = (error: ErrorEvent) => {
      setHasError(true);
    };

    window.addEventListener("error", handleOnError);

    return () => {
      window.removeEventListener("error", handleOnError);
    };
  }, []);

  useEffect(() => {
    setHasError(false);
  }, [location]);

  if (hasError) {
    return (
      <Layout pageType={"error"}>
        <ApiError />
      </Layout>
    );
  }

  return <>{props.children}</>;
};

export default ErrorBoundary;
