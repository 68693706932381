import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import UserProfileNav from "../../Molecules/UserProfileNav";
import { useMediaQuery } from "react-responsive";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";

interface HeaderAdminProps {
  userData: {
    identity: any;
    dashboard: any;
    robots: any;
  };
}

const HeaderAdmin = (props: HeaderAdminProps) => {
  const { t } = useTranslation("translation");
  const [navActive, setNavActive] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const isTabletDevice = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });

  const handleClick = () => {
    setNavActive(!navActive);
  };

  const handleProfileClick = () => {
    setProfileOpen(!profileOpen);
  };

  return (
    <>
      <div className="bg-white w-full z-50   md:relative fixed top-0">
        <div className="flex max-w-body px-4 mx-auto gap-5 ">
          <div className="w-full flex gap-2.5">
            <RouterLink to="/admin" className="mt-2 mb-4">
              <img src="/img/scout-logo.png" alt="SCOUT" className="lg:mt-7 md:mt-7 lg:h-10 md:h-10 h-8 mt-2.5" />
            </RouterLink>
          </div>
          {(isTabletDevice || isMobileDevice) && (
            <>
              <div
                className={`${
                  navActive ? " right-0" : "-right-full"
                } top-0 sidebar-gradient fixed w-screen h-screen z-50 transition-all px-5 py-2 flex flex-col`}>
                <div className="flex">
                  <div className="w-full flex gap-2.5">
                    <img src="/img/scout-logo-white.png" alt="SCOUT" className="h-8 mt-3" />
                  </div>
                  <span className="material-symbols-outlined text-white font-normal text-2xl h-fit mt-3" onClick={handleClick}>
                    close
                  </span>
                </div>
                <li className="list-none mt-10 h-full">
                  <ul className="mb-5">
                    <RouterLink to="/admin" className="font-medium text-white text-xl" onClick={handleClick}>
                      {t("Admin")}
                    </RouterLink>
                    <RouterLink to="/admin/parts-types" className="font-medium text-white text-xl" onClick={handleClick}>
                      {t("Part types")}
                    </RouterLink>
                    <RouterLink to="/admin/manufacturing-references" className="font-medium text-white text-xl" onClick={handleClick}>
                      {t("Manufacturing references")}
                    </RouterLink>
                  </ul>

                  <ul className={`${profileOpen ? "h-60" : " h-10"} overflow-hidden transition-all`}>
                    <p className="font-medium text-white text-xl flex" onClick={handleProfileClick}>
                      {t("Profile")}
                      <span className={`${profileOpen && "rotate-180"} transition-all material-symbols-outlined mt-1 ml-2 `}>expand_more</span>
                    </p>

                    <li className="pl-5 mt-3">
                      <ul className="mb-3">
                        <RouterLink to={`/user/${props.userData.identity.id}`} className="font-medium text-white text-xl" onClick={handleClick}>
                          {t("Profile")}
                        </RouterLink>
                      </ul>
                      <ul className="mb-3">
                        <RouterLink
                          to={`/user/${props.userData.identity.id}/change-password`}
                          className="font-medium text-white text-xl"
                          onClick={handleClick}>
                          {t("Change Password")}
                        </RouterLink>
                      </ul>
                      <ul className="mb-3">
                        <RouterLink to="/user/list" className="font-medium text-white text-xl" onClick={handleClick}>
                          {t("View users created")}
                        </RouterLink>
                      </ul>

                      <ul className="mb-3">
                        <RouterLink to="/logout" className="font-medium text-white text-xl" onClick={handleClick}>
                          {t("Logout")}
                        </RouterLink>
                      </ul>
                    </li>
                  </ul>
                </li>
                <Footer></Footer>
              </div>
            </>
          )}
          {!isMobileDevice && (
            <div className="py-9 flex gap-5">
              <div className="gap-7 py-2.5 w-max lg:flex hidden">
                <RouterLink to="/admin" className="font-medium text-black text-base w-max hover:text-rose-700">
                  {t("Admin")}
                </RouterLink>
                <RouterLink to="/admin/parts-types" className="font-medium text-black text-base w-max hover:text-rose-700">
                  {t("Part types")}
                </RouterLink>
                <RouterLink to="/admin/manufacturing-references" className="font-medium text-black text-base w-max hover:text-rose-700">
                  {t("Manufacturing references")}
                </RouterLink>
              </div>

              <div className="lg:block hidden">
                <UserProfileNav
                  name={props.userData.identity.name}
                  email={props.userData.identity.email}
                  id={props.userData.identity.id}></UserProfileNav>
              </div>
            </div>
          )}

          <div className={`${isMobileDevice ? "mt-6" : "sm:mt-12 mt-6"} menu relative w-7 h-5  lg:hidden block cursor-pointer`} onClick={handleClick}>
            <div className="absolute mt-0 w-5 h-0.5 bg-neutral-400 rounded-full"></div>
            <div className="absolute mt-2 w-5 h-0.5 bg-neutral-400 rounded-full"></div>
            <div className="absolute mt-4 w-5 h-0.5 bg-neutral-400 rounded-full"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderAdmin;
