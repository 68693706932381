import axios from "axios";

export const getRobot = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/return-robot?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};


export const getRobots = async (page, limit, search, status = 0, signal: any) => {
  const realSearch = search === "" ? "false" : search;
  const offset = page * limit;
  const url = `/api/v2/robots/get-robots-view?limit=${limit}&offset=${offset}&search=${realSearch}&robot=${false}&farm=${false}&house=${false}&range=${false}&status=${status}`;
  const { data } = await axios.get(url, { signal });
  return data;
};


export const getRobotCameras = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/get-cameras?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getRobotStatus = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/get-robot-status-layout-data?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const associateRobotToHouse = (houseId) => {
  return `/api/v2/houses/associate-robot-to-house?houseId=${houseId}`;
};

export const getRobotStatusSettings = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/get-robot-status?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getRobotSettings = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/get-robot-settings?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getUsersInRobot = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/get-robot-users?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getHouseDimensions = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/get-house-dimensions?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getConfigurationFile = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/get-configuration-file?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getRobotRails = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/get-robot-rails?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getImages = async (
  robotId: string,
  offset: number,
  galleryType: string,
  limit: number,
  camera: string,
  startDate: any,
  endDate: string,
  signal: any
) => {
  const url = `/api/v2/robots/get-gallery-images-observable?robotId=${robotId}&offset=${offset}&observableName=${galleryType}&limit=${limit}${
    camera === "all" ? `` : `&camera=${camera}`
  }${startDate === null ? `` : `&from=${startDate}&to=${endDate}`}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getGraph = async (roundId: string, observable: string, time: string, signal: any) => {
  const url = `/api/v2/robots/get-graph-data?roundId=${roundId}&observableName=${observable}&type=interval&time=${time}&col=mean`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getRoundGraph = async (roundId: string, observable: string, signal: any) => {
  const url = `/api/v2/robots/get-graph-data?roundId=${roundId}&observableName=${observable}&type=round&time=graph&col=mean`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getHourMap = async (roundId: string, observable: string, signal: any) => {
  const url = `/api/v2/robots/get-hour-map?roundId=${roundId}&observableName=${observable}&col=total`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getApiKey = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/get-robot-api-key?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getExportParams = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/export-params?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

/* 
POST ROUTES WHITOUT AXIOS
*/


export const confirmRobotAsociation = (expires, userId, signature) => {
  return `/api/v2/robots/confirm-robot-association-api?expires=${expires}&request=${userId}&signature=${signature}`;
};

export const addFarmManagerToRobot = () => {
  return `/api/v2/robots/add-farm-manager-to-robot`;
};

export const addRobot = () => {
  return `/api/v2/robots/add-robot`;
};

export const associateUserToRobot = (robotId) => {
  return `/api/v2/robots/associate-user-to-robot?robotId=${robotId}`;
};

export const updateConfigFile = (robotId) => {
  return `/api/v2/robots/update-configuration-file?robotId=${robotId}`;
};

export const editRobotSettings = (robotId) => {
  return `/api/v2/robots/edit-robot-settings?robotId=${robotId}`;
};

export const associateHouseToRobot = (robotId) => {
  return `/api/v2/robots/associate-house-to-robot?robotId=${robotId}`;
};

export const showVideo = (robotId, camera) => {
  return `/api/v2/robots/show-video?robotId=${robotId}&typeId=normal_video&cameraId=${camera}`;
};

export const updateRobot = (robotId) => {
  return `/api/v2/robots/update-robot?robotId=${robotId}`;
};

export const updateRobotStatus = (type, robotId, value) => {
  return `/api/v2/robots/update-${type}-status?robotId=${robotId}&${type}=${value}`;
};
export const updateActionStatus = (robotId, value) => {
  return `/api/v2/robots/update-action-status?robotId=${robotId}&action=${value}`;
};
export const deleteApiKey = (apiKey) => {
  return `/api/v2/robots/delete-api-key?key=${apiKey}`;
};
export const generateApiKey = (robotId) => {
  return `/api/v2/robots/generate-robot-api-key?robotId=${robotId}`;
};

export const deleteUserFromRobot = (robotId, userId?) => {
  return `/api/v2/robots/remove-user-from-robot?id=${robotId} ${userId ? `&userId=${userId}` : ``} `;
};
