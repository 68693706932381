import axios from "axios";

export const getAlerts = async (page, limit, range, farm, house, robot, signal: any) => {
  const offset = page * limit;
  const url = `/api/v2/alerts/get-alerts-view?limit=${limit}&offset=${offset}&search=${false}&robot=${robot}&farm=${farm}&house=${house}&range=${range}&status=${true}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const alertSubscription = () => {
  return `/api/v2/alerts/alert-subscription`;
};

export const getConfigureAlertsParams = (robotId) => {
  return `/api/v2/alerts/get-configure-alerts-params?robotId=${robotId}`;
};

export const checkIfRoundHasEnded = (robotId) => {
  return `/api/v2/alerts/check-if-round-has-ended?robotId=${robotId}`;
};

export const checkIfRoundExistsForRobot = (robotId) => {
  return `/api/v2/alerts/check-if-round-exists-for-robot?robotId=${robotId}`;
};

export const getSmartAlertConfig = async (houseId, userId: string, signal: any) => {
  const url = `/api/v2/alerts/get-smart-alerts-config?houseId=${houseId}&userId=${userId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const createSmartAlert = (houseId, userId) => {
  return `/api/v2/alerts/create-smart-alert-config?houseId=${houseId}&userId=${userId}`;
};
export const updateSmartAlert = (houseId, userId) => {
  return `/api/v2/alerts/update-smart-alert-config?houseId=${houseId}&userId=${userId}`;
};

export const deleteSmartAlert = (houseId, userId, observable) => {
  return `/api/v2/alerts/delete-smart-alert-config?houseId=${houseId}&userId=${userId}&observable=${observable}`;
};
