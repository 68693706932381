import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation_en from "./locales/en_US/translation.json";
import translation_es from "./locales/es_ES/translation.json";
import translation_ca from "./locales/ca_ES/translation.json";
import translation_fr from "./locales/fr_FR/translation.json";
import translation_de from "./locales/de_DE/translation.json";
import translation_hu from "./locales/hu_HU/translation.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en_US",
    lng: "en_US",
    debug: true,
    resources: {
      en_US: {
        translation: translation_en,
      },
      es_ES: {
        translation: translation_es,
      },
      ca_ES: {
        translation: translation_ca,
      },
      fr_FR: {
        translation: translation_fr,
      },
      de_DE: {
        translation: translation_de,
      },
      hu_HU: {
        translation: translation_hu,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
