import axios from "axios";

export const getFarm = async (farmId: string, signal: any) => {
  const url = `/api/v2/farms/return-farm?farmId=${farmId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getHousesInFarm = async (farmId: string, signal: any) => {
  const url = `/api/v2/farms/get-houses-in-farm?farmId=${farmId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export function getFarms(page, limit, search, signal: any) {
  const realSearch = search === "" ? "false" : search;
  const offset = page * limit;
  const url = `/api/v2/farms/get-farms-view?limit=${limit}&offset=${offset}&search=${realSearch}&robot=${false}&farm=${false}&house=${false}&range=${false}&status=${true}`;
  const req = axios.get(url, { signal });
  return req;
}
export const addFarm = () => {
  return `/api/v2/farms/add-farm`;
};
export const updateFarm = (farmId) => {
  return `/api/v2/farms/update-farm?farmId=${farmId}`;
};
export const deleteFarm = (farmId) => {
  return `/api/v2/farms/delete?farmId=${farmId}`;
};
