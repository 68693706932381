import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AppState from "../../../models/AppState";

interface protectedRoutesProps {
  identity: any;
  isSignedIn: any;
  children: any;
}

const ProtectedRoutes = (props: protectedRoutesProps) => {
  const [isAllowed, setIsAllowed] = useState(true);
  const location = useLocation();
  const routerParams = location.pathname.split("/");

  useEffect(() => {
    if ((routerParams[0] === "admin" || routerParams[1] === "admin") && parseInt(props.identity.user_role) !== 3) {
      setIsAllowed(false);
    } else {
      setIsAllowed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, props.identity.user_role]);

  if (props.isSignedIn) {
    if (isAllowed) {
      return props.children;
    } else {
      if (parseInt(props.identity.user_role) === 3) {
        return <Navigate to="/admin" replace />;
      }
      return <Navigate to="/home" replace />;
    }
  } else {
    return <Navigate to="/" replace />;
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    identity: state.userData.identity,
    isSignedIn: state.userData.isSessionActive,
  };
};

export default connect(mapStateToProps)(ProtectedRoutes);
