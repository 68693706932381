import axios from "axios";

export const updateHouseTargets = (houseId) => {
  return `/api/v2/curves/update-house-targets?houseId=${houseId}`;
};

export const getHouseTargets = async (houseId, signal) => {
  const url = `/api/v2/curves/get-house-targets?houseId=${houseId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const updateRoundTargets = (roundId) => {
  return `/api/v2/curves/update-round-targets?roundId=${roundId}`;
};

export const getRoundTargets = async (roundId, signal: any) => {
  const url = `/api/v2/curves/get-round-targets?roundId=${roundId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
