import React from "react";
import { useTranslation } from "react-i18next";

import { Link as RouterLink } from "react-router-dom";

interface footerInterface {}

export const Footer = (props: footerInterface) => {
  const { t } = useTranslation("translation");
  const { i18n } = useTranslation("translation");
  const year = new Date().getFullYear();

  const handleLanguage = (e: any) => {
    i18n.changeLanguage(e.target.value);
  };
  return (
    <div className="">
      <div className="lg:flex lg:bg-white lg:p-10 gap-10 pb-10">
        <select
          value={i18n.language}
          onChange={handleLanguage}
          className="mt-3 cursor-pointer block px-2 py-0.5 rounded text-sm text-gray-900 bg-white h-full w-auto border hover:bg-neutral-100">
          <option value="en_US">EN</option>
          <option value="es_ES">ES</option>
          <option value="ca_ES">CA</option>
          <option value="de_DE">DE</option>
          <option value="hu_HU">HU</option>
        </select>
        <img src="/img/agcoLogo.png" alt="AGCO Corp" className="h-10 my-5 lg:my-0" />

        <div className="lg:flex grid grid-cols-1 w-full lg:gap-5 my-0 lg:my-3">
          <p className="lg:text-neutral-800 text-white lg:w-auto w-full lg:text-sm text-sm lg:mb-0 mb-3">
            © Copyright {year} AGCO Corporation. All Rights Reserved.
          </p>
          <RouterLink
            className="lg:text-neutral-400 lg:w-auto w-full text-white lg:text-sm text-sm lg:mb-0 mb-3"
            to="https://www.agcocorp.com/privacy/">
            {t("Privacy")}
          </RouterLink>
          <RouterLink
            className="lg:text-neutral-400 lg:w-auto w-full text-white lg:text-sm text-sm lg:mb-0 mb-3"
            to="https://www.agcocorp.com/cookies/">
            {t("Cookies")}
          </RouterLink>
          <RouterLink
            className="lg:text-neutral-400 lg:w-auto w-full text-white lg:text-sm text-sm lg:mb-0 mb-3"
            to="https://www.agcocorp.com/ccpa-notice-at-collection.html">
            {t("California Notice at Collection")}
          </RouterLink>
          <RouterLink
            className="lg:text-neutral-400 lg:w-auto w-full text-white lg:text-sm text-sm lg:mb-0 mb-3"
            to="https://www.agcocorp.com/do-not-sell-my-information.html ">
            {t("Do Not Sell or Share My Personal Information")}
          </RouterLink>

          <div className="lg:text-neutral-400 lg:w-auto w-full text-white lg:text-sm text-sm lg:mb-0 mb-3" id="teconsent"></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
