export enum actionTypes {
  INIT_USER,
  SET_USER_DATA,
  UPDATE_USER_DATA,
  INIT_STATUS_LAYOUT,
  SET_STATUS_LAYOUT_DATA,
  GET_ROBOT_STATUS,
  SET_NEW_ROBOT_STATUS_DATA,
  INIT_STATUS_AMBIENT_CONDITIONS,
  INIT_STATUS_EQUIPMENT,
  INIT_STATUS_HEALTH_AND_WELFARE,
  SET_AMBIENT_CONDITIONS_OBSERVABLE,
  SET_EQUIPMENT_OBSERVABLE,
  SET_HEALTH_AND_WELFARE_OBSERVABLE,
  SET_ACTIVE_TAB,
  INIT_REPORTS,
  INIT_REPORT_TAB_DETAILS,
  ADD_REPORT_OBSERVABLE,
  REMOVE_REPORT_OBSERVABLE,
  SET_REPORT_TABS,
  SET_REPORT_CHART_DATA,
  REPORT_CHART_LOADED,
  SET_REPORT_FORM_PARAMS,
  SET_ACTIVE_REPORT_TAB,
  HIDE_REPORT_TAB,
  ADD_NEW_REPORT_TAB,
  SAVE_ALL_REPORTS_TABS_CLICKED,
  SAVE_ALL_REPORTS_TABS_FAILURE,
  SAVE_ALL_REPORTS_TABS_SUCCESS,
  UPDATE_REPORT_TABS_ON_DROP,
  EXTRACT_REPORT_CHART_DETAILS,
  SET_NEW_REPORT_CHART_DETAIL,
  UPDATE_REPORT_CHARTS_ON_DROP,
  REPORT_TAB_CONTENT_LOADED,
  UPDATE_REPORT_TAB_NAME,
  UPDATE_REPORT_CHART_POSITION,
  UPDATE_SLIDER_LAYOUT,
  UPDATE_REPORT_SLIDER_LAYOUT,
  GET_ROBOT_STATUS_PAGE_DATA,
  SET_ROBOT_STATUS_PAGE_DATA,
  GET_MANAGEMENT_PAGES_DATA,
  SET_MANAGEMENT_PAGES_DATA,
  UPDATE_MANAGEMENT_PAGES_DATA,
  ADD_MANAGEMENT_PAGES_DATA,
  SET_VIEWS_PAGE_DATA,
  GET_VIEWS_PAGE_DATA,
  GET_ROBOT_SETTINGS,
  SET_ROBOT_SETTINGS,
  UPDATE_ROBOT_SETTINGS,
  UPDATE_SLIDER_POSITION,
  GET_USER_LOGIN_STATUS,
  SET_USER_LOGIN_STATUS,
  LOGGED_IN,
  LOGGED_OUT,
  LOAD_CLICKED_TAB_DATA,
  SET_PRODUCTION_CYCLE_DATA,
  GET_DEFAULT_ROUND,
  INIT_DEFAULT_PC,
  SET_ANOMALY_IMAGE,
  SET_FARM_DASHBOARD_PICKED,
  SET_DASHBOARD_FILTERS,
  SET_IMAGE_GALLERY_PICKED,
  REFETCH_USER_DATA,
  SET_REDIRECT_URL,
}
