import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface ApiErrorProps {}

const ApiError = (props: ApiErrorProps) => {
  const { t } = useTranslation("translation");
  const TITLE = t("Technical problems");

  return (
    <>
      <title>{TITLE}</title>

      <div className="max-w-body lg:px-4 mx-auto">
        <div className="grid lg:grid-cols-5 grid-cols-1 gap-10 lg:px-0 px-5">
          <img className="lg:col-span-3 lg:mt-20 mt-28" src="/img/error.svg" alt="ERROR" />
          <div className="lg:mt-32 mt-3 lg:col-span-2">
            <p className="lg:text-5xl text-3xl font-semibold mb-1">{t("Sorry")},</p>
            <p className="lg:text-5xl text-3xl font-semibold mb-1">{t("We are experiencing some technical problems")}</p>
            <p className="lg:text-2xl text-xl mt-5">{t("Please try again later")}</p>
            <div className="flex">
              <RouterLink className="px-4 py-2 bg-neutral-800 text-white lg:font-2xl text-xl mt-3 rounded" to="/home">
                {"Go home"}
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiError;
