import axios from "axios";

export const getCustomObservables = async (roundId: string, signal: any) => {
  const url = `/api/v2/rounds/get-custom-observables?roundId=${roundId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getProdCycle = async (roundId: string, signal: any) => {
  let url = `/api/v2/rounds/return-round?roundId=${roundId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getProdCycleTargets = async (roundId: string, signal: any) => {
  let url = `/api/v2/rounds/return-targets?roundId=${roundId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getProdCycles = (page, limit, range, farm, house, robot, status, signal: any) => {
  const offset = page * limit;
  const url = `/api/v2/rounds/get-rounds-view?limit=${limit}&offset=${offset}&search=${false}&robot=${robot}&farm=${farm}&house=${house}&range=${range}&status=${status}`;
  const req = axios.get(url, { signal });
  return req;
};
export const closeRound = (roundId) => {
  return `/api/v2/rounds/close-round?roundId=${roundId}`;
};
export const deleteRound = (roundId) => {
  return `/api/v2/rounds/delete?roundId=${roundId}`;
};
export const updateRound = (roundId) => {
  return `/api/v2/rounds/update-round?roundId=${roundId}`;
};
export const updateThinning = (roundId) => {
  return `/api/v2/rounds/update-round?roundId=${roundId}&thinning=true`;
};
export const addRound = () => {
  return `/api/v2/rounds/add-round`;
};
export const createCustomObservables = (roundId) => {
  return `/api/v2/rounds/create-custom-observables?roundId=${roundId}`;
};
export const updateCustomObservables = (roundId) => {
  return `/api/v2/rounds/update-custom-observables?roundId=${roundId}`;
};
