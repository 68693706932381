import React, { useState, useEffect } from 'react';

function HelpStepper({ data }) {
    const [currentStep, setCurrentStep] = useState(-1);
    const initialZoom = 2.5;
    const [zoomLevel, setZoomLevel] = useState(initialZoom);
    const [imageWidth, setImageWidth] = useState(640);
    const [imageHeight, setImageHeight] = useState(640);

    useEffect(() => {
        const stepElements = document.querySelectorAll("#step");
        const targetStepElement = stepElements[currentStep];

        if (targetStepElement) {
            const imageElement = targetStepElement.querySelector("img"); // Assuming the image is a direct child of the step element
            if (imageElement) {
                setImageHeight(imageElement.clientHeight);
                setImageWidth(imageElement.clientWidth);
            }
        }
    }, [currentStep]);

    useEffect(() => {
        setCurrentStep(-1);
        setZoomLevel(initialZoom);
    }, [data]);

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
        setZoomLevel(initialZoom);
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
        setZoomLevel(initialZoom);

    };

    const handleZoomIn = () => {
        setZoomLevel(zoomLevel + 0.5);
    };

    const handleZoomOut = () => {
        if (zoomLevel > 0.2) {
            setZoomLevel(zoomLevel - 0.5);
        }
    };

    const stepsList = data.steps_list;

    return (
        <div className="px-4 h-full">
            <div className={`flex flex-col mt-6 ${currentStep === -1 ? 'block' : 'hidden'}`}>
                <div className={`bg-gray-100 rounded`}>
                    <div className="flex items-center mb-10">
                        <div className={`w-10 h-10 p-4 rounded-full bg-gray-500 flex items-center justify-center p-2`}>
                            <span className={`text-white font-semibold material-symbols-outlined`}>
                                info
                            </span>
                        </div>
                    </div>
                    <div className="relative  my-10 overflow-hidden flex-grow">
                        <h1 className='text-center h-[30vh]'>
                            Welcome to {data.title} guide!
                            <p className="text-center text-gray-500">{data.category} | {data.time} seconds | {data.steps_size} steps</p>
                        </h1>
                    </div>
                </div>
            </div>
            {stepsList.map((step, index) => (
                <div key={index} id='step' className={`flex flex-col mt-6 ${index === currentStep ? 'block' : 'hidden'}`}>
                    {step.image ?
                        <div className={`bg-${step.color}-0 rounded`}>
                            <div className="flex items-center mb-10">
                                <div className={`w-10 h-10 p-4 rounded-full bg-${step.color}-100 flex items-center justify-center mr-4`}>
                                    <span className={`text-${step.color}-700 text-lg font-semibold`}>{index + 1}</span>
                                </div>
                                <div>
                                    <p className="text-coolGray-300">{step.text}</p>
                                </div>
                            </div>
                            <div className="relative  my-10 overflow-hidden rounded flex-grow hover:opacity-100 group">
                                <div className="h-[30vh] w-full mx-auto overflow-hidden" style={{ transform: `scale(${zoomLevel})`, transformOrigin: `${imageWidth * (step.pos.x / 100)}px ${imageHeight * (step.pos.y / 100)}px` }}>
                                    <img id='stepImage' src={step.image} alt={`Step ${index + 1}`} className="w-full h-auto transform transition-transform" />
                                    <div
                                        className={`absolute rounded-full bg-orange-300 bg-opacity-30 border border-orange-400 border-opacity-60`}
                                        style={{
                                            top: `${imageHeight * (step.pos.y / 100)}px`,
                                            left: `${imageWidth * (step.pos.x / 100)}px`,
                                            transform: `translate(-50%, -50%)`,
                                            width: `${50 / (zoomLevel + 1)}px`,
                                            height: `${50 / (zoomLevel + 1)}px`,
                                            borderWidth: `${5 / (zoomLevel + 1)}px`
                                        }}
                                    ></div>
                                </div>
                                <div className="absolute bottom-2 right-2 flex gap-2 opacity-0 group-hover:opacity-100">
                                    <button
                                        className="px-4 py-2 text-sm font-medium text-white bg-gray-500 rounded"
                                        onClick={handleZoomIn}
                                    >
                                        +
                                    </button>
                                    <button
                                        className="px-4 py-2 text-sm font-medium text-white bg-gray-500 rounded"
                                        onClick={handleZoomOut}
                                        disabled={zoomLevel <= 1}
                                    >
                                        -
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={`bg-${step.color}-100 rounded`}>
                            <div className="flex items-center mb-10">
                                <div className={`w-10 h-10 p-4 rounded-full bg-${step.color}-500 flex items-center justify-center p-2`}>
                                    <span className={`text-${step.color}-700 font-semibold material-symbols-outlined`}>
                                        info
                                    </span>
                                </div>
                            </div>
                            <div className="relative my-10 overflow-hidden flex-grow h-[30vh] flex items-center justify-center">
                                <p className="text-center text-md px-5">
                                    {step.text}
                                </p>
                            </div>
                        </div>
                    }

                </div>
            ))}
            <div className={`flex flex-col mt-6 ${currentStep === stepsList.length ? 'block' : 'hidden'}`}>
                <div className={`bg-gray-100 rounded`}>
                    <div className="flex items-center mb-10">
                        <div className={`w-10 h-10 p-4 rounded-full bg-gray-500 flex items-center justify-center p-2`}>
                            <span className={`text-white font-semibold material-symbols-outlined`}>
                                check
                            </span>
                        </div>
                    </div>
                    <div className="text-center relative  my-10 overflow-hidden flex-grow">
                        <h1 className='h-[30vh]'>
                            Congratulations, you've completed the {data.title} guide!
                        </h1>
                        <button
                            className={`text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-400 transition-all font-medium rounded-lg px-3 py-1 text-center inline-flex items-center dark:focus:ring-indigo-300 mb-1`} onClick={() => setCurrentStep(-1)}
                        >
                            Restart guide
                        </button>
                    </div>
                </div>
            </div>
            {currentStep !== stepsList.length &&
                <div className="flex justify-between items-center mt-10 mx-5">
                    <div className="flex items-center flex-grow">
                        <button
                            className="font-medium text-white rounded"
                            onClick={() => setCurrentStep(-1)}
                        >
                            <span className="material-symbols-outlined text-indigo-500 pt-1">home</span>
                        </button>
                        <div className="w-full mx-3">
                            <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                                <div
                                    className="bg-indigo-600 h-1.5 rounded-full  transition-all"
                                    style={{ width: `${((currentStep + 1) / stepsList.length) * 100}%` }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <button
                            type="button"
                            className={`text-white ${currentStep === -1 ? 'bg-[#cccccc] cursor-default' : 'bg-[#bfbcbc] hover:bg-[#cccccc]/90 focus:ring-4 focus:outline-none focus:ring-[#bfbcbc]/50'} font-medium rounded-lg text-xs px-3 text-center inline-flex items-center dark:focus:ring-[#bfbcbc]/55 mr-2 mb-1`}
                            onClick={handlePrev}
                            disabled={currentStep === -1}
                        >
                            <span className="material-symbols-outlined text-base">arrow_back</span>
                        </button>
                        <button
                            type="button"
                            onClick={handleNext}
                            disabled={currentStep === stepsList.length}
                            className={`text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-400 transition-all font-medium rounded-lg px-3 py-1 text-center inline-flex items-center dark:focus:ring-indigo-300 mb-1`}
                        >
                            <span className="material-symbols-outlined text-base mr-2">arrow_forward</span> {currentStep === -1 ? "Get Started" : currentStep === stepsList.length - 1 ? "Finish" : "Next"}
                        </button>
                    </div>
                </div>
            }
        </div>
    );

}

export default HelpStepper;
