import React, { Suspense, useEffect, useRef, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUserLoginStatus, loggedIn, loggedOut, refetchUserData, setRedirectUrl } from "../../actions/InitUserAction";
import AppState from "../../models/AppState";
import Layout from "../../Layout";
import Pages from "../../Pages";
import Protected from "../../components/Organisms/Protected";
import UnProtected from "../../components/Organisms/UnProtected";
import { Spinner } from "../../components/Atoms/Spinners";
import ErrorBoundary from "../../components/Atoms/ErrorBoundary";
import i18n from "../../i18n";

interface AppProps {
  userData;
  getUserLoginStatus;
  loggedIn;
  form;
  loggedOut;
  setUserData;
  setRedirectUrl;
  refetchUserData;
}

const App = (props: AppProps) => {
  const [pageType, setPage]: any = useState("OtherPages");
  const location = useLocation();
  const prevIsSessionActive = useRef(props.userData.isSessionActive);
  const prevLang = useRef(props.userData.identity.lang);
  const prevPathname = useRef(location.pathname);

  useEffect(() => {
    setPageType();
    setLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkIfSignedIn();
    const intervalId: any = setInterval(checkIfSignedIn, 10 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalId: any = setInterval(() => {
      props.refetchUserData();
    }, 1 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.userData.isSessionActive !== prevIsSessionActive.current || location.pathname !== prevPathname.current) {
      setPageType();
    }
    if (props.userData.isSessionActive !== prevIsSessionActive.current || props.userData.identity.lang !== prevLang.current) {
      setLanguage();
    }
    prevIsSessionActive.current = props.userData.isSessionActive;
    prevLang.current = props.userData.identity.lang;
    prevPathname.current = location.pathname;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userData.isSessionActive, props.userData.identity.lang, location.pathname]);

  const checkIfSignedIn = () => {
    props.getUserLoginStatus();
    if (!props.userData.isSessionActive && location.pathname !== "/") {
      props.setRedirectUrl(location.pathname + location.search);
    }
  };

  const isPageLoaded = () => {
    return props.userData.identity.id !== "";
  };

  const setLanguage = (languageParam = null) => {
    const language = {
      Spanish: "es_ES",
      Catalan: "ca_ES",
      German: "de_DE",
      English: "en_US",
      default: "en_US",
      French: "fr_FR",
      Hungarian: "hu_HU",
    };
    if (languageParam != null) {
      const loginLanguage = language[languageParam];
      i18n.changeLanguage(loginLanguage);
    } else if (isPageLoaded()) {
      const userLanguage = language[props.userData.identity.lang];
      i18n.changeLanguage(userLanguage);
    }
  };

  const setPageType = () => {
    const loc = location.pathname;
    const robotStatusPages = /\/(\w+)\/(robot-status|settings|image-gallery|ambient-conditions|equipment|health-and-welfare)\/(.+)/;
    const loginPages = /\/(login|register|index)/;
    const manualPage = /\/(user-manual)/;

    if (loginPages.test(loc) || manualPage.test(loc) || props.userData.isSessionActive === false) {
      setPage("Login");
    } else if (robotStatusPages.test(loc)) {
      setPage("RobotStatusPages");
    } else {
      setPage("OtherPages");
    }
  };

  return (
    <div className="h-full">
      {props.userData.isSessionActive !== null && (isPageLoaded() || pageType === "Login") ? (
        <ErrorBoundary>
          <Layout pageType={pageType}>
            <Suspense
              fallback={
                <>
                  <div className="w-28 h-28 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <Spinner />
                  </div>
                </>
              }>
              <Routes>
                {/*
                ************************
                    Unprotected routes
                ************************
                */}
                <Route
                  path="/"
                  element={
                    <UnProtected>
                      <Pages.Login loggedIn={props.loggedIn} />
                    </UnProtected>
                  }
                />
                <Route
                  path="/confirm-account"
                  element={
                    <UnProtected>
                      <Pages.ConfirmAccount />
                    </UnProtected>
                  }
                />
                <Route
                  path="/resend-verification-email"
                  element={
                    <UnProtected>
                      <Pages.ResendVerificationEmail setLanguage={setLanguage} params={props.form.ResendVerificationEmail} />
                    </UnProtected>
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <UnProtected>
                      <Pages.ResetPassword />
                    </UnProtected>
                  }
                />
                <Route
                  path="/set-password"
                  element={
                    <UnProtected>
                      <Pages.SetPassword />
                    </UnProtected>
                  }
                />
                {/*
                ************************
                    Protected routes
                ************************
                */}
                {/* General routes */}
                <Route
                  path="/logout"
                  element={
                    <Protected>
                      <Pages.Logout loggedOut={props.loggedOut} />
                    </Protected>
                  }
                />
                <Route
                  path="/home"
                  element={
                    <Protected>
                      <Pages.Dashboard checkIfSignedIn={checkIfSignedIn} />
                    </Protected>
                  }
                />
                <Route
                  path="/reports"
                  element={
                    <Protected>
                      <Pages.Reports identity={props.userData.identity} />
                    </Protected>
                  }
                />
                <Route
                  path="/docs"
                  element={
                    <Protected>
                      <Pages.Documentation />
                    </Protected>
                  }
                />
                <Route
                  path="/error"
                  element={
                    <Protected>
                      <Pages.ErrorPage />
                    </Protected>
                  }
                />
                {/* Robot routes */}
                <Route
                  path="/robot/:robotId/status"
                  element={
                    <Protected>
                      <Pages.RobotStatus />
                    </Protected>
                  }
                />
                <Route
                  path="/robot/list"
                  element={
                    <Protected>
                      <Pages.ViewRobots />
                    </Protected>
                  }
                />
                <Route
                  path="/robot/:robotId/image-gallery/:galleryType"
                  element={
                    <Protected>
                      <Pages.ImageGallery />
                    </Protected>
                  }
                />
                <Route
                  path="/robot/:robotId"
                  element={
                    <Protected>
                      <Pages.RobotSettings />
                    </Protected>
                  }
                />
                <Route
                  path="/robot/:robotId/video"
                  element={
                    <Protected>
                      <Pages.VideoStreaming />
                    </Protected>
                  }
                />
                <Route
                  path="/robot/:robotId/graphs"
                  element={
                    <Protected>
                      <Pages.RobotGraphs />
                    </Protected>
                  }
                />
                <Route
                  path="/robot/add"
                  element={
                    <Protected>
                      <Pages.AddRobot />
                    </Protected>
                  }
                />
                <Route
                  path="/robots/confirm-robot-association"
                  element={
                    <Protected>
                      <Pages.ConfirmUserAssociation />
                    </Protected>
                  }
                />
                <Route
                  path="/robot/:robotId/export-data"
                  element={
                    <Protected>
                      <Pages.RobotExportData />
                    </Protected>
                  }
                />
                {/* Farms routes */}
                <Route
                  path="/farm/create"
                  element={
                    <Protected>
                      <Pages.CreateFarm identity={props.userData.identity} />
                    </Protected>
                  }
                />
                <Route
                  path="/farm/list"
                  element={
                    <Protected>
                      <Pages.ViewFarms />
                    </Protected>
                  }
                />
                <Route
                  path="/farm/:farmId"
                  element={
                    <Protected>
                      <Pages.ViewFarm identity={props.userData.identity} />
                    </Protected>
                  }
                />
                {/* House routes */}
                <Route
                  path="/house/list"
                  element={
                    <Protected>
                      <Pages.ViewHouses />
                    </Protected>
                  }
                />
                <Route
                  path="/house/:houseId"
                  element={
                    <Protected>
                      <Pages.ViewHouse />
                    </Protected>
                  }
                />
                <Route
                  path="/house/:houseId/status"
                  element={
                    <Protected>
                      <Pages.HouseStatus />
                    </Protected>
                  }
                />
                <Route
                  path="/house/:houseId/graphs"
                  element={
                    <Protected>
                      <Pages.HouseGraphs />
                    </Protected>
                  }
                />
                <Route
                  path="/house/create"
                  element={
                    <Protected>
                      <Pages.CreateHouse />
                    </Protected>
                  }
                />
                {/* Rounds routes */}
                <Route
                  path="/cycle/list"
                  element={
                    <Protected>
                      <Pages.ViewProdCycles />
                    </Protected>
                  }
                />
                <Route
                  path="/cycle/:roundId"
                  element={
                    <Protected>
                      <Pages.ViewProdCycle />
                    </Protected>
                  }
                />
                <Route
                  path="/cycle/create"
                  element={
                    <Protected>
                      <Pages.CreateProdCycle />
                    </Protected>
                  }
                />
                {/* Users routes */}
                <Route
                  path="/user/list"
                  element={
                    <Protected>
                      <Pages.ViewUsers />
                    </Protected>
                  }
                />
                <Route
                  path="/user/:userId"
                  element={
                    <Protected>
                      <Pages.ViewUser identity={props.userData.identity} setLanguage={setLanguage} />
                    </Protected>
                  }
                />
                <Route
                  path="/user/:userId/change-password"
                  element={
                    <Protected>
                      <Pages.ChangePassword />
                    </Protected>
                  }
                />
                <Route
                  path="/user/create"
                  element={
                    <Protected>
                      <Pages.CreateUser />
                    </Protected>
                  }
                />
                {/* Alerts routes */}
                <Route
                  path="/alert/list"
                  element={
                    <Protected>
                      <Pages.ViewAlerts userData={props.userData} />
                    </Protected>
                  }
                />
                {/* Admin routes */}
                <Route
                  path="/admin"
                  element={
                    <Protected>
                      <Pages.AdminPanel />
                    </Protected>
                  }
                />
                <Route
                  path="/admin/create-robot"
                  element={
                    <Protected>
                      <Pages.CreateRobot />
                    </Protected>
                  }
                />
                <Route
                  path="/admin/parts-types"
                  element={
                    <Protected>
                      <Pages.PartsTypes />
                    </Protected>
                  }
                />
                <Route
                  path="/admin/parts-types/create"
                  element={
                    <Protected>
                      <Pages.CreatePartType />
                    </Protected>
                  }
                />
                <Route
                  path="/admin/part-type/:id"
                  element={
                    <Protected>
                      <Pages.PartType />
                    </Protected>
                  }
                />
                <Route
                  path="/admin/robot/:robotId/parts"
                  element={
                    <Protected>
                      <Pages.RobotParts />
                    </Protected>
                  }
                />
                <Route
                  path="/admin/manufacturing-references"
                  element={
                    <Protected>
                      <Pages.ManufacturingReferences />
                    </Protected>
                  }
                />
                <Route
                  path="/admin/manufacturing-references/create"
                  element={
                    <Protected>
                      <Pages.CreateManufacturingReference />
                    </Protected>
                  }
                />
                <Route
                  path="/admin/robot/:robotId/observables"
                  element={
                    <Protected>
                      <Pages.RobotObservables />
                    </Protected>
                  }
                />

                <Route
                  path="*"
                  element={
                    <div className="max-w-body lg:px-4 mx-auto ">
                      <h1 className="text-3xl font-semibold text-left mt-10">404 ERROR</h1>
                      <h2 className="text-lg text-left mt-3">This page doesn't exist</h2>
                    </div>
                  }
                />
              </Routes>
            </Suspense>
          </Layout>
        </ErrorBoundary>
      ) : (
        <div className="w-28 h-28 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Spinner />
        </div>
      )}
      <NotificationContainer />
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    userData: state.userData,
    form: state.form,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      loggedIn,
      loggedOut,
      getUserLoginStatus,
      setRedirectUrl,
      refetchUserData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
