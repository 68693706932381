import React from "react";
import { GridLoader, BeatLoader } from "react-spinners";

export const Spinner: any = () => {
  return <GridLoader size={30} color={"#c00031"} />;
};

export const SmallLoader: any = () => {
  return (
    <GridLoader
      size={20}
      color={"#c00031"} //A1CAB0
    />
  );
};

export const DotsLoader: any = () => {
  return (
    <BeatLoader
      size={15}
      color={"#c00031"} //A1CAB0
    />
  );
};

export const DotsLoaderWhite: any = () => {
  return (
    <BeatLoader
      size={15}
      color={"#fff"} //A1CAB0
    />
  );
};

export const MiniDotsLoader: any = () => {
  return <BeatLoader size={9} color={"rgb(16 185 129)"} />;
};
