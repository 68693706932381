import axios from "axios";

export const getHouse = async (houseId: string, signal: any) => {
  const url = `/api/v2/houses/return-house?houseId=${houseId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export function getHouses(page, limit, search, farm, signal: any) {
  const realSearch = search === "" ? "false" : search;
  const offset = page * limit;
  const url = `/api/v2/houses/get-houses-view?limit=${limit}&offset=${offset}&search=${realSearch}&robot=${false}&farm=${farm}&house=${false}&range=${false}&status=${true}`;
  const req = axios.get(url, { signal });
  return req;
}

export const getRobotsInHouse = async (houseId: string, signal: any) => {
  const url = `/api/v2/houses/get-robots-in-house?houseId=${houseId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const returnActiveHousesForHouseSwitch = async (robotId: string, signal) => {
  const url = `/api/v2/houses/return-active-houses-for-robot-switch?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getHouseSiUnits = async (houseId: string, signal: any) => {
  const url = `/api/v2/houses/get-si-unit?houseId=${houseId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getCreateHouseParams = async (signal: any) => {
  const url = `/api/v2/houses/get-create-house-params`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const updateHouse = (houseId) => {
  return `/api/v2/houses/update-house?houseId=${houseId}`;
};

export const deleteHouse = (houseId) => {
  return `/api/v2/houses/delete?houseId=${houseId}`;
};

export const addHouse = () => {
  return `/api/v2/houses/add-house`;
};

export const getUnlikedRobotsIOwn = (houseId) => {
  return `/api/v2/houses/get-unlinked-robots-i-own?houseId=${houseId}`;
};

export const createMapElement = (houseId) => {
  return `/api/v2/houses/create-map-element?houseId=${houseId}`;
};
export const createMapFigure = (houseId) => {
  return `/api/v2/houses/create-map-figure?houseId=${houseId}`;
};
export const deleteMapObject = (id) => {
  return `/api/v2/houses/delete-map-object?id=${id}`;
};
export const updateMapFigure = (id) => {
  return `/api/v2/houses/update-map-figure?id=${id}`;
};
export const updateMapElement = (id) => {
  return `/api/v2/houses/update-map-element?id=${id}`;
};
export const getMapObjects = async (houseId: string, signal: any) => {
  const url = `/api/v2/houses/get-map-objects?houseId=${houseId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
