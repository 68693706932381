export const fixedData = (data: any) => {
  const res: any = [];

  if (data) {
    data.forEach((value: any, index: number) => {
      res[index] = value !== null ? value.toFixed(2) : value;
    });
  }
  return res;
};

export const getIsoDate00 = (dateObj) => {
  const date = new Date(dateObj);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const newDate = `${year}-${month}-${day}T00:00:00.00Z`;
  return newDate;
};

export const fixedDates = (dates: any, type: string, roundStart: any, robotType: string) => {
  const res: any = [];
  if (dates) {
    const initDate: any = new Date(roundStart);
    initDate.setHours(0);

    dates.forEach((value: any, index: number) => {
      let date = new Date(value);

      if (type === "hour" && robotType === "hb") {
        date = new Date(value + ":00");
      }
      const today = new Date(date);
      today.setHours(0);

      res[index] = date.toISOString();
    });
  }

  return res;
};

export const differenceOfDays = (startDate, endDate) => {
  const initDate: any = new Date(startDate);
  const today = new Date(endDate);
  initDate.setHours(0);
  today.setHours(0);

  const diff = today.getTime() - initDate.getTime();
  const day = Math.round(diff / (1000 * 60 * 60 * 24));

  return day;
};

// this function gets a date and add the amout of days to it
export const addDaysToDate = (date, days) => {
  var res = new Date(date);
  res.setDate(res.getDate() + days);
  return res;
};

export const subDaysToDate = (date, days) => {
  var res = new Date(date);
  res.setDate(res.getDate() - days);
  return res;
};

export const calculateCustomObservableData = (graphs, roundStart, roundEnd, position) => {
  const dates: any = [];
  const data: any = [];
  const notes: any = [];
  const end =
    differenceOfDays(getIsoDate(new Date()), getIsoDate(roundEnd)) <= 0
      ? addDaysToDate(getIsoDate(roundEnd), 1).toISOString()
      : addDaysToDate(new Date(), 1).toISOString();

  const daysOfProdTillNow = differenceOfDays(roundStart, end);

  graphs.forEach((day, index) => {
    if (index > 0 && index <= daysOfProdTillNow) {
      const dayNum = parseInt(day[0].value);
      const thisDay = getIsoDate00(addDaysToDate(roundStart, dayNum));
      // we need to know if the date is between our filter
      dates.push(thisDay);
      if (position === -1) {
        data.push(null);
      } else {
        data.push(day[position].value);
      }
      notes.push(day[4].value !== "" ? day[4].value : null);
    }
  });

  return {
    dates: dates,
    data: data,
    notes: notes,
  };
};

export const calculateAnotations = (graphs, roundStart, roundEnd) => {
  //const dates: any = [];
  const notes: any = [];
  const end =
    differenceOfDays(getIsoDate(new Date()), getIsoDate(roundEnd)) <= 0
      ? addDaysToDate(getIsoDate(roundEnd), 1).toISOString()
      : addDaysToDate(new Date(), 1).toISOString();

  const daysOfProdTillNow = differenceOfDays(roundStart, end);

  graphs.forEach((day, index) => {
    if (index > 0 && index <= daysOfProdTillNow) {
      //const dayNum = parseInt(day[0].value);
      //const thisDay = getIsoDate00(addDaysToDate(roundStart, dayNum));
      // we need to know if the date is between our filter
      //dates.push(thisDay);
      notes.push(day[4].value);
    }
  });

  return notes;
};

export const OBSERVABLE_CONFIG = {
  metric: {
    temperature: { unit: "°c", deviation_type: "both", red: 40, green: 5, yellow: 10 },
    effective_temperature: { unit: "°c", deviation_type: "both", red: 40, green: 1, yellow: 2 },
    humidity: { unit: "%", deviation_type: "both", red: 40, green: 5, yellow: 10 },
    airspeed: { unit: "m/s", deviation_type: "both", red: 40, green: 1, yellow: 0.3 },
    co2: { unit: "ppm", deviation_type: "upper", red: 300, green: 100, yellow: 200 },
    digestion_index: { unit: "%", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    light_intensity: { unit: "lux", deviation_type: "upper", red: 40, green: 5, yellow: 10 },
    predicted_frequency: { unit: "b/m", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    ammonia: { unit: "ppm", deviation_type: "upper", red: 40, green: 2, yellow: 5 },
    heat_stress_index: { unit: "", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    sound_level: { unit: "", deviation_type: "upper", red: 40, green: 10, yellow: 20 },
    humidex: { unit: "%", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    free_space: { unit: "%", deviation_type: "lower", red: 40, green: 5, yellow: 10 },
    weight_estimation: { unit: "g", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    flicker: { unit: "%", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    strayed_eggs: { unit: "", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    feathers_score: { unit: "", deviation_type: "both", red: 40, green: 5, yellow: 15 },
  },
  imperial: {
    temperature: { unit: "kelvin", deviation_type: "both", red: 40, green: 5, yellow: 10 },
    effective_temperature: { unit: "kelviin", deviation_type: "both", red: 40, green: 1, yellow: 2 },
    humidity: { unit: "%", deviation_type: "both", red: 40, green: 5, yellow: 10 },
    airspeed: { unit: "m/s", deviation_type: "both", red: 40, green: 0.1, yellow: 0.3 },
    co2: { unit: "ppm", deviation_type: "upper", red: 300, green: 100, yellow: 200 },
    digestion_index: { unit: "%", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    light_intensity: { unit: "lux", deviation_type: "upper", red: 40, green: 5, yellow: 10 },
    predicted_frequency: { unit: "b/m", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    ammonia: { unit: "ppm", deviation_type: "upper", red: 40, green: 2, yellow: 5 },
    heat_stress_index: { unit: "", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    sound_level: { unit: "", deviation_type: "upper", red: 40, green: 10, yellow: 20 },
    humidex: { unit: "%", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    free_space: { unit: "%", deviation_type: "lower", red: 40, green: 5, yellow: 10 },
    weight_estimation: { unit: "g", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    flicker: { unit: "%", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    strayed_eggs: { unit: "", deviation_type: "both", red: 40, green: 5, yellow: 15 },
    feathers_score: { unit: "", deviation_type: "both", red: 40, green: 5, yellow: 15 },
  },
};

export const compareIfStatusActive = (dateString: string, currentDate: any): boolean => {
  if (dateString === null) return false;
  // Parse the date in the format "YYYY-MM-DD HH:mm:ss" into a Date object
  const date = new Date(dateString.replace(/-/g, "/") + " UTC");
  const twoMinutesInMilliseconds = 2 * 60 * 1000; // 2 minutes in milliseconds
  // Calculate the difference between the current date and the provided date
  const timeDifferenceInMilliseconds = currentDate.getTime() - date.getTime();
  // Compare if the difference is greater than 2 minutes in milliseconds
  return timeDifferenceInMilliseconds < twoMinutesInMilliseconds;
};

export const getIsoDate = (dateObj) => {
  const date = new Date(dateObj);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const newDate = `${year}-${month}-${day}T23:59:59.00Z`;
  return newDate;
};

/*
const { Buffer } = require("buffer");

export function decompressJSON(compressedB64) {
  const compressed = Buffer.from(compressedB64, "base64");
  const decompressed = Zlib.inflateSync(compressed, { encoding: "binary" }).toString();
  const jsonData = JSON.parse(decompressed);
  return jsonData;
}

*/

export const getFormatedDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
