import UserData from "../../models/AppState/UserData";
import initializeState from "../../App";
import { setUserData, setUserLoginStatus } from "../../actions/InitUserAction";
import { actionTypes } from "../../actions/types";
import { loop, Cmd, LoopReducer } from "redux-loop";
import { AnyAction } from "redux";
import { editData, fetchUserData, fetchUserLoginStatus } from "../../util/ApiCalls";

type initUserType = LoopReducer<UserData, AnyAction>;

const initUser: initUserType = (state: any = initializeState.userData, action: any) => {
  switch (action.type) {
    case actionTypes.LOGGED_IN:
      return loop(
        { ...state, isSessionActive: true },
        Cmd.run(fetchUserData, {
          successActionCreator: setUserData,
          failActionCreator: setUserData,
        })
      );

    case actionTypes.LOGGED_OUT:
      return { ...initializeState.userData, isSessionActive: false };

    case actionTypes.GET_USER_LOGIN_STATUS:
      return loop(
        { ...state },
        Cmd.run(fetchUserLoginStatus, {
          successActionCreator: setUserLoginStatus,
          failActionCreator: setUserLoginStatus,
        })
      );

    case actionTypes.SET_USER_LOGIN_STATUS:

      const isSessionActive = action.payload.response?.isSessionActive;

      if (isSessionActive === undefined) {
        // Return the current state or handle this case as needed.
        return state;
      }
   
      if (state.isSessionActive === isSessionActive) {
        return state;
      } else {
        if (state.isSessionActive !== true && isSessionActive === true) {
          return loop(
            { ...state, isSessionActive },
            Cmd.run(fetchUserData, {
              successActionCreator: setUserData,
              failActionCreator: setUserData,
            })
          );
        } else {
          const identity = { name: "", role: "", email: "", id: "", lang: "", user_role: "" };
          return { ...state, isSessionActive, identity };
        }
      }

    case actionTypes.SET_USER_DATA:
      const {identity = {}, dashboard = {}, farms = [], houses = [], robots = [], alerts = [], rounds = [], users = []} = action.payload.response || {};
      return { ...state, identity, dashboard, farms, houses, robots, alerts, rounds, users };

    case actionTypes.UPDATE_USER_DATA:
      const result = editData(state[action.payload.type], action.payload.type, action.payload.data);
      return {
        ...state,
        [action.payload.type]: result,
      };

    case actionTypes.SET_FARM_DASHBOARD_PICKED:
      state.farmDashBoardPicked = action.payload.farmId;
      return {
        ...state,
        farmDashBoardPicked: action.payload.farmId,
      };
    case actionTypes.SET_REDIRECT_URL:
      state.redirectUrl = action.payload.url;
      return {
        ...state,
        redirectUrl: action.payload.url,
      };

    case actionTypes.SET_DASHBOARD_FILTERS:
      state.filters = action.payload.filters;
      return {
        ...state,
        filters: action.payload.filters,
      };

    case actionTypes.REFETCH_USER_DATA:
      return loop(
        { ...state },
        Cmd.run(fetchUserData, {
          successActionCreator: setUserData,
          failActionCreator: setUserData,
        })
      );

    default:
      return state;
  }
};

export default initUser;
