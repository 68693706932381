import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HelpModal from "../../Atoms/HelpModal";
import HelpStepper from "../HelpStepper";

interface HelpButtonProps {
  children?: any;
}

const HelpButton = (props: HelpButtonProps) => {
  const { t } = useTranslation("translation");
  const [showHelp, setShowHelp] = useState(false);

  const guides = [
    {
      title: "Share a robot",
      category: "Scout Monitoring",
      steps_size: "6",
      time: "21",
      steps_list: [
        {
          id: "1",
          text: "Click here.",
          image: "/img/how_to_share_a_robot/1.png",
          color: "blue",
          pos: {
            x: "34",
            y: "52",
          },
        },
        {
          id: "2",
          text: 'Click "settings"',
          image: "/img/how_to_share_a_robot/2.png",
          color: "blue",
          pos: {
            x: "81.3",
            y: "26",
          },
        },
        {
          id: "3",
          text: 'Click "Associate new User"',
          image: "/img/how_to_share_a_robot/3.png",
          color: "blue",
          pos: {
            x: "79",
            y: "80",
          },
        },
        {
          id: "4",
          text: "Enter the user email, click this dropdown and select his role",
          image: "/img/how_to_share_a_robot/4.png",
          color: "blue",
          pos: {
            x: "43",
            y: "53",
          },
        },
        {
          id: "5",
          text: 'Click "Submit"',
          image: "/img/how_to_share_a_robot/5.png",
          color: "blue",
          pos: {
            x: "62",
            y: "57.5",
          },
        },
        {
          id: "6",
          text: "If you see the success message, tell the new user to check their email and accept the invitation",
          image: "/img/how_to_share_a_robot/6.png",
          color: "blue",
          pos: {
            x: "56.7",
            y: "42.5",
          },
        },
      ],
    },
    {
      title: "How to add a robot, a house and a farm",
      category: "Scout Monitoring",
      steps_size: "14",
      time: "24",
      steps_list: [
        {
          id: "1",
          text: "Add the robot first!",
          color: "green",
          pos: null,
          image: null,
        },
        {
          id: "2",
          text: 'Click "Robot"',
          color: "blue",
          pos: {
            x: "77",
            y: "6",
          },
          image: "/img/how_to_add_a_robot_a_house_and_a_farm/2.png",
        },
        {
          id: "3",
          text: 'Click "Add new robot"',
          color: "blue",
          pos: {
            x: "81.3",
            y: "26",
          },
          image: "/img/how_to_add_a_robot_a_house_and_a_farm/3.png",
        },
        {
          id: "4",
          text: 'Add your master token and click "Add robot"',
          color: "blue",
          pos: {
            x: "49.5",
            y: "52",
          },
          image: "/img/how_to_add_a_robot_a_house_and_a_farm/4.png",
        },
        {
          id: "5",
          text: "Add the farm now!",
          color: "green",
          pos: null,
          image: null,
        },
        {
          id: "6",
          text: 'Click "farm"',
          color: "blue",
          pos: {
            x: "69",
            y: "6",
          },
          image: "/img/how_to_add_a_robot_a_house_and_a_farm/6.png",
        },
        {
          id: "7",
          text: 'Click "Create a farm"',
          color: "blue",
          pos: {
            x: "82",
            y: "26",
          },
          image: "/img/how_to_add_a_robot_a_house_and_a_farm/7.png",
        },
        {
          id: "8",
          text: 'Enter all required values and click "Create"',
          color: "blue",
          pos: {
            x: "81.5",
            y: "70.2",
          },
          image: "/img/how_to_add_a_robot_a_house_and_a_farm/8.png",
        },
        {
          id: "9",
          text: "The last thing is to create the house!",
          color: "green",
          pos: null,
          image: null,
        },
        {
          id: "10",
          text: 'Click "house"',
          color: "blue",
          pos: {
            x: "72.7",
            y: "6",
          },
          image: "/img/how_to_add_a_robot_a_house_and_a_farm/10.png",
        },
        {
          id: "11",
          text: 'Click "Create a house"',
          color: "blue",
          pos: {
            x: "82",
            y: "26",
          },
          image: "/img/how_to_add_a_robot_a_house_and_a_farm/11.png",
        },
        {
          id: "12",
          text: 'Enter all required values and click "Create"',
          color: "blue",
          pos: {
            x: "81.5",
            y: "71",
          },
          image: "/img/how_to_add_a_robot_a_house_and_a_farm/12.png",
        },
        {
          id: "13",
          text: "Check if your robot, house and farm appear on the dashboard!",
          color: "green",
          pos: null,
          image: null,
        },
        {
          id: "14",
          text: "Check if your robot, house and farm appear on the dashboard!",
          color: "blue",
          pos: {
            x: "50",
            y: "55",
          },
          image: "/img/how_to_add_a_robot_a_house_and_a_farm/14.png",
        },
      ],
    },
    {
      title: "How to use robot graph page",
      category: "Scout Monitoring",
      steps_size: "19",
      time: "41",
      steps_list: [
        {
          id: "1",
          text: 'Click "Observables"',
          color: "blue",
          pos: {
            x: "69",
            y: "33.5",
          },
          image: "/img/how_to_use_robot_graph_page\\1.png",
        },
        {
          id: "2",
          text: "Select all the observables you want to compare",
          color: "blue",
          pos: {
            x: "47.5",
            y: "71",
          },
          image: "/img/how_to_use_robot_graph_page\\2.png",
        },
        {
          id: "3",
          text: "You can activate or deactivate the targets",
          color: "blue",
          pos: {
            x: "23.2",
            y: "33.5",
          },
          image: "/img/how_to_use_robot_graph_page\\3.png",
        },
        {
          id: "4",
          text: "You can see all the observables on the same line chart!",
          color: "green",
          pos: {
            x: "0",
            y: "0",
          },
        },
        {
          id: "5",
          text: 'Click "All Together"',
          color: "blue",
          pos: {
            x: "17",
            y: "33.5",
          },
          image: "/img/how_to_use_robot_graph_page\\5.png",
        },
        {
          id: "6",
          text: "Here you can set the interval by hour, day or round",
          color: "blue",
          pos: {
            x: "77",
            y: "33.5",
          },
          image: "/img/how_to_use_robot_graph_page\\6.png",
        },
        {
          id: "7",
          text: "Here you can configure the view: linear graph, heat map or hourly map",
          color: "blue",
          pos: {
            x: "81",
            y: "33.5",
          },
          image: "/img/how_to_use_robot_graph_page\\7.png",
        },
        {
          id: "8",
          text: "If you always use the same configuration you can save it!",
          color: "green",
          pos: null,
        },
        {
          id: "9",
          text: 'Click "configurations"',
          color: "blue",
          pos: {
            x: "23.2",
            y: "33.5",
          },
          image: "/img/how_to_use_robot_graph_page\\9.png",
        },
        {
          id: "10",
          text: 'Click "Add configuration"',
          color: "blue",
          pos: {
            x: "34.5",
            y: "48.5",
          },
          image: "/img/how_to_use_robot_graph_page\\10.png",
        },
        {
          id: "11",
          text: "Give your current configuration a name",
          color: "blue",
          pos: {
            x: "40",
            y: "41",
          },
          image: "/img/how_to_use_robot_graph_page\\11.png",
        },
        {
          id: "12",
          text: 'Click "Save configuration"',
          color: "blue",
          pos: {
            x: "60",
            y: "67",
          },
          image: "/img/how_to_use_robot_graph_page\\12.png",
        },
        {
          id: "13",
          text: "Click on it to activate the configuration",
          color: "blue",
          pos: {
            x: "24.5",
            y: "43.5",
          },
          image: "/img/how_to_use_robot_graph_page\\13.png",
        },
        {
          id: "14",
          text: 'If you want to clean the graph page click "filter off"',
          color: "blue",
          pos: {
            x: "26.4",
            y: "33.5",
          },
          image: "/img/how_to_use_robot_graph_page\\14.png",
        },
        {
          id: "15",
          text: 'Click "more" to see the information of the configuration or delete it',
          color: "blue",
          pos: {
            x: "37",
            y: "43.5",
          },
          image: "/img/how_to_use_robot_graph_page\\15.png",
        },
        {
          id: "16",
          text: 'Click "Delete"',
          color: "blue",
          pos: {
            x: "61",
            y: "62",
          },
          image: "/img/how_to_use_robot_graph_page\\16.png",
        },
        {
          id: "17",
          text: "You can narrow your charts by date!",
          color: "green",
          pos: null,
        },
        {
          id: "18",
          text: 'Click "calendar"',
          color: "blue",
          pos: {
            x: "27.8",
            y: "33.5",
          },
          image: "/img/how_to_use_robot_graph_page\\18.png",
        },
        {
          id: "19",
          text: "Select range",
          color: "blue",
          pos: {
            x: "37",
            y: "42",
          },
          image: "/img/how_to_use_robot_graph_page\\19.png",
        },
      ],
    },
    {
      title: "How to create a production cycle",
      category: "Scout Monitoring",
      steps_size: "9",
      time: "34",
      steps_list: [
        {
          id: "1",
          text: 'Click "Production Cycles"',
          color: "blue",
          pos: {
            x: "57",
            y: "6",
          },
          image: "/img/how_to_create_a_production_cycle\\1.png",
        },
        {
          id: "2",
          text: 'Click "Create a Prod cycle"',
          color: "blue",
          pos: {
            x: "81.3",
            y: "26",
          },
          image: "/img/how_to_create_a_production_cycle\\2.png",
        },
        {
          id: "3",
          text: "Select the farm",
          color: "blue",
          pos: {
            x: "22",
            y: "38",
          },
          image: "/img/how_to_create_a_production_cycle/3.png",
        },
        {
          id: "4",
          text: "Select the house",
          color: "blue",
          pos: {
            x: "22",
            y: "47",
          },
          image: "/img/how_to_create_a_production_cycle\\4.png",
        },
        {
          id: "5",
          text: 'If there is an active production cycle you will see this notice, click  "close"',
          color: "blue",
          pos: {
            x: "64.8",
            y: "44.2",
          },
          image: "/img/how_to_create_a_production_cycle\\5.png",
        },
        {
          id: "6",
          text: "Select the data range of the production cycle",
          color: "blue",
          pos: {
            x: "49.7",
            y: "66.5",
          },
          image: "/img/how_to_create_a_production_cycle\\6.png",
        },
        {
          id: "7",
          text: "Enter the number of chickens in the production cycle",
          color: "blue",
          pos: {
            x: "45",
            y: "48",
          },
          image: "/img/how_to_create_a_production_cycle\\7.png",
        },
        {
          id: "8",
          text: "Select the specie",
          color: "blue",
          pos: {
            x: "68",
            y: "38.5",
          },
          image: "/img/how_to_create_a_production_cycle\\8.png",
        },
        {
          id: "9",
          text: 'Click "Create"',
          color: "blue",
          pos: {
            x: "81.3",
            y: "79.5",
          },
          image: "/img/how_to_create_a_production_cycle\\9.png",
        },
      ],
    },
    {
      title: "How to use report page",
      category: "Scout Monitoring",
      steps_size: "31",
      time: "2",
      steps_list: [
        {
          id: "1",
          text: 'Click "Reports"',
          color: "blue",
          pos: {
            x: "64",
            y: "6",
          },
          image: "/img/how_to_use_report_page\\1.png",
        },
        {
          id: "2",
          text: 'Click "Create your first report"',
          color: "blue",
          pos: {
            x: "50",
            y: "61",
          },
          image: "/img/how_to_use_report_page\\2.png",
        },
        {
          id: "3",
          text: 'Add a name to your new report and click "Create"',
          color: "blue",
          pos: {
            x: "62",
            y: "57",
          },
          image: "/img/how_to_use_report_page\\3.png",
        },
        {
          id: "4",
          text: 'Click "Add graph"',
          color: "blue",
          pos: {
            x: "50",
            y: "61",
          },
          image: "/img/how_to_use_report_page\\4.png",
        },
        {
          id: "5",
          text: "Select the farm",
          color: "blue",
          pos: {
            x: "43",
            y: "52",
          },
          image: "/img/how_to_use_report_page\\5.png",
        },
        {
          id: "6",
          text: "Select the house",
          color: "blue",
          pos: {
            x: "52",
            y: "52",
          },
          image: "/img/how_to_use_report_page\\6.png",
        },
        {
          id: "7",
          text: "Configure the settings of your new graph",
          color: "blue",
          pos: {
            x: "54",
            y: "47",
          },
          image: "/img/how_to_use_report_page\\7.png",
        },
        {
          id: "8",
          text: 'Click "Select prod cycles"',
          color: "blue",
          pos: {
            x: "40",
            y: "65",
          },
          image: "/img/how_to_use_report_page\\8.png",
        },
        {
          id: "9",
          text: "You can select up to 9 different production cycles",
          color: "blue",
          pos: {
            x: "44",
            y: "50",
          },
          image: "/img/how_to_use_report_page\\9.png",
        },
        {
          id: "10",
          text: 'Click "Add"',
          color: "blue",
          pos: {
            x: "54.2",
            y: "67.5",
          },
          image: "/img/how_to_use_report_page\\10.png",
        },
        {
          id: "11",
          text: 'Click "Select Observables"',
          color: "blue",
          pos: {
            x: "40",
            y: "70",
          },
          image: "/img/how_to_use_report_page\\11.png",
        },
        {
          id: "12",
          text: "If you have selected a single production cycle you can select more than one observable, if not you can only select one",
          color: "green",
          pos: {
            x: null,
            y: null,
          },
        },
        {
          id: "13",
          text: "Select the observables",
          color: "blue",
          pos: {
            x: "34",
            y: "44",
          },
          image: "/img/how_to_use_report_page\\13.png",
        },
        {
          id: "14",
          text: 'Click "Add"',
          color: "blue",
          pos: {
            x: "62.5",
            y: "77",
          },
          image: "/img/how_to_use_report_page\\14.png",
        },
        {
          id: "15",
          text: "How to duplicate a graph!",
          color: "green",
          pos: {
            x: null,
            y: null,
          },
        },
        {
          id: "16",
          text: 'Click "copy"',
          color: "blue",
          pos: {
            x: "82",
            y: "42",
          },
          image: "/img/how_to_use_report_page\\16.png",
        },
        {
          id: "17",
          text: 'Click "Duplicate"',
          color: "blue",
          pos: {
            x: "47",
            y: "53",
          },
          image: "/img/how_to_use_report_page\\17.png",
        },
        {
          id: "18",
          text: "How to edit a graph!",
          color: "green",
          pos: {
            x: null,
            y: null,
          },
        },
        {
          id: "19",
          text: 'Click "edit"',
          color: "blue",
          pos: {
            x: "84",
            y: "52",
          },
          image: "/img/how_to_use_report_page\\19.png",
        },
        {
          id: "20",
          text: "You can configure everything from that graph",
          color: "blue",
          pos: {
            x: "44",
            y: "64",
          },
          image: "/img/how_to_use_report_page\\20.png",
        },
        {
          id: "21",
          text: 'Click "Save"',
          color: "blue",
          pos: {
            x: "62.5",
            y: "74",
          },
          image: "/img/how_to_use_report_page\\21.png",
        },
        {
          id: "22",
          text: "How to delete a graph!",
          color: "green",
          pos: {
            x: null,
            y: null,
          },
        },
        {
          id: "23",
          text: 'Click "delete"',
          color: "blue",
          pos: {
            x: "15",
            y: "42",
          },
          image: "/img/how_to_use_report_page\\23.png",
        },
        {
          id: "24",
          text: 'Click "Delete"',
          color: "blue",
          pos: {
            x: "47",
            y: "53",
          },
          image: "/img/how_to_use_report_page\\24.png",
        },
        {
          id: "25",
          text: "How to edit and delete a report!",
          color: "green",
          pos: {
            x: null,
            y: null,
          },
        },
        {
          id: "26",
          text: "Click on reports dropdown",
          color: "blue",
          pos: {
            x: "17",
            y: "34",
          },
          image: "/img/how_to_use_report_page\\26.png",
        },
        {
          id: "27",
          text: 'Click "more"',
          color: "blue",
          pos: {
            x: "29",
            y: "44",
          },
          image: "/img/how_to_use_report_page\\27.png",
        },
        {
          id: "28",
          text: "You can change the name",
          color: "blue",
          pos: {
            x: "29",
            y: "42",
          },
          image: "/img/how_to_use_report_page\\28.png",
        },
        {
          id: "29",
          text: 'Click "Save"',
          color: "blue",
          pos: {
            x: "31",
            y: "48",
          },
          image: "/img/how_to_use_report_page\\29.png",
        },
        {
          id: "30",
          text: 'Click "delete"',
          color: "blue",
          pos: {
            x: "25",
            y: "48",
          },
          image: "/img/how_to_use_report_page\\30.png",
        },
        {
          id: "31",
          text: 'Click "Delete"',
          color: "blue",
          pos: {
            x: "47",
            y: "53",
          },
          image: "/img/how_to_use_report_page\\31.png",
        },
      ],
    },
    {
      title: "How to configure alerts",
      category: "Scout Monitoring",
      steps_size: "13",
      time: "30",
      steps_list: [
        {
          id: "1",
          text: 'Click "Configure alerts"',
          color: "blue",
          pos: {
            x: "80",
            y: "72",
          },
          image: "/img/how_to_configure_alerts\\1.png",
        },
        {
          id: "2",
          text: 'Go to the robot status page and click "notifications"',
          color: "blue",
          pos: {
            x: "83",
            y: "37",
          },
          image: "/img/how_to_configure_alerts\\2.png",
        },
        {
          id: "3",
          text: "Click on the time frame you want to set",
          color: "blue",
          pos: {
            x: "39",
            y: "20",
          },
          image: "/img/how_to_configure_alerts\\3.png",
        },
        {
          id: "4",
          text: "Each period of time will let you configure the alerts regarding the average of the data that exists in that time. In other words, if you configure round, the value that will be used will be the average of the data for the entire round.",
          color: "green",
          pos: {
            x: "50",
            y: "50",
          },
        },
        {
          id: "5",
          text: "If the observable falls below that value, the alert is triggered",
          color: "blue",
          pos: {
            x: "40",
            y: "38",
          },
          image: "/img/how_to_configure_alerts\\5.png",
        },
        {
          id: "6",
          text: "If the observable goes above that value, the alert is triggered",
          color: "blue",
          pos: {
            x: "55",
            y: "38",
          },
          image: "/img/how_to_configure_alerts\\6.png",
        },
        {
          id: "7",
          text: "If the data taken by the robot with respect to the target is less than that value, the alert is activated",
          color: "blue",
          pos: {
            x: "40",
            y: "53",
          },
          image: "/img/how_to_configure_alerts\\7.png",
        },
        {
          id: "8",
          text: "If the data taken by the robot with respect to the objective is higher than that value, the alert is activated",
          color: "blue",
          pos: {
            x: "54",
            y: "53",
          },
          image: "/img/how_to_configure_alerts\\8.png",
        },
        {
          id: "9",
          text: "Waiting time until the alert is sent again if the problem has not been solved",
          color: "blue",
          pos: {
            x: "41",
            y: "67",
          },
          image: "/img/how_to_configure_alerts\\9.png",
        },
        {
          id: "10",
          text: 'Click "Subscribe"',
          color: "blue",
          pos: {
            x: "61",
            y: "76",
          },
          image: "/img/how_to_configure_alerts\\10.png",
        },
        {
          id: "11",
          text: "You can edit or delete your alerts!",
          color: "green",
          pos: {
            x: "50",
            y: "50",
          },
        },
        {
          id: "12",
          text: "Click on the configured alert",
          color: "blue",
          pos: {
            x: "39",
            y: "20",
          },
          image: "/img/how_to_configure_alerts\\12.png",
        },
        {
          id: "13",
          text: 'Edit the values and click "Save" or click "Unsubscribe" to delete the alert',
          color: "blue",
          pos: {
            x: "56",
            y: "76",
          },
          image: "/img/how_to_configure_alerts\\13.png",
        },
      ],
    },
  ];

  return (
    <>
      {showHelp && <div onClick={() => setShowHelp(!showHelp)} className="bg-black opacity-50 fixed w-full h-full top-0 left-0 z-50"></div>}

      <div
        className={`${
          !showHelp ? "rounded-full shadow-sm w-10 h-10 overflow-hidden" : "rounded-lg shadow lg:w-96 w-80 h-96 overflow-y-scroll"
        } transition-all fixed lg:bottom-10 bottom-14 lg:right-10 right-3 z-50 bg-white  hover:shadow text-left border`}>
        <p className="border-neutral-200 border-b lg:px-5 px-3 lg:py-3 py-2 lg:text-2xl text-lg w-full text-black font-medium">{t("Help menu")}</p>
        <div className="grid grid-cols-1 lg:mt-3 mt-2 mb-7">
          {guides.map((guide, index) => (
            <HelpModal key={index} title={guide.title}>
              <HelpStepper data={guide}></HelpStepper>
            </HelpModal>
          ))}
        </div>
      </div>
      <div
        onClick={() => setShowHelp(!showHelp)}
        className={`${
          !showHelp ? "rounded-full shadow-sm border hover:shadow z-50" : "rounded-lg z-30"
        } transition-all w-10 h-10 fixed lg:bottom-10 bottom-14 lg:right-10 right-3  cursor-pointer bg-white text-center`}>
        {!showHelp ? (
          <span className="material-symbols-outlined text-xl my-1.5">question_mark</span>
        ) : (
          <span className="material-symbols-outlined text-xl my-1.5">close</span>
        )}
      </div>
    </>
  );
};

export default HelpButton;
