import { createStore, compose } from 'redux';
import initializeState  from './App';
import reducers from './reducers';
import { install, StoreCreator } from 'redux-loop';


declare var window: any;

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancer(
	install()
);
const enhancedStoreCreator = createStore as StoreCreator;
export const appStore:any =  enhancedStoreCreator(reducers, initializeState, enhancer); 