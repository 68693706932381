import axios from "axios";

export const getAdminInfo = async (signal: any) => {
  const url = `/api/v2/admin/get-summary`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getPartsTypes = async (signal: any) => {
  const url = `/api/v2/admin/get-parts-types`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getPartType = async (id: string, signal: any) => {
  const url = `/api/v2/admin/get-part-type?id=${id}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const deletePartType = (partTypeId) => {
  return `/api/v2/admin/delete-part-type?id=${partTypeId}`;
};

export const createPartType = () => {
  return `/api/v2/admin/create-part-type`;
};

export const updatePartType = (partTypeId) => {
  return `/api/v2/admin/update-part-type?id=${partTypeId}`;
};

export const getRobotParts = async (robotId, signal: any) => {
  const url = `/api/v2/admin/get-robot-parts?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getAllObservables = async (signal: any) => {
  const url = `/api/v2/robots/get-all-available-observables`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const getAllRobotObservables = async (robotId: string, signal: any) => {
  const url = `/api/v2/robots/get-robot-available-observables?robotId=${robotId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const deleteRobotPart = (id) => {
  return `/api/v2/admin/delete-robot-part?id=${id}`;
};

export const addPartToRobot = (robotId, partId) => {
  return `/api/v2/admin/add-part-to-robot?robotId=${robotId}&partId=${partId}`;
};

export const getRobotPartHistory = async (id, signal: any) => {
  const url = `/api/v2/admin/get-robot-part-history?id=${id}`;
  const { data } = await axios.get(url, { signal });
  return data;
};

export const addActionToHistory = (id) => {
  return `/api/v2/admin/add-update-to-history?id=${id}`;
};

export const getManufacturingReferences = async (signal: any) => {
  const url = `/api/v2/admin/get-manufacturing-references`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const createManufacturingReference = () => {
  return `/api/v2/admin/create-manufacturing-reference`;
};
export const updateManufacturingReference = (id) => {
  return `/api/v2/admin/update-manufacturing-reference?id=${id}`;
};
export const deleteManufacturingReference = (id) => {
  return `/api/v2/admin/delete-manufacturing-reference?id=${id}`;
};

export const addRobotObservable = (robotId: string, observableId: string) => {
  return `/api/v2/robots/add-observable-to-robot?robotId=${robotId}&observable=${observableId}`;
};

export const deleteRobotObservable = (robotId: string, observableId: string) => {
  return `/api/v2/robots/delete-observable-from-robot?robotId=${robotId}&observable=${observableId}`;
};
