import { useNavigate } from "react-router-dom";

export const useErrorHandling = () => {
  const navigate = useNavigate();

  const handleQueryError = (error) => {
    console.log("Error:", error.response.status, error.response.data.message);
    if (error.response.status === 401) navigate("/home");
    else navigate("/error");
  };

  return {
    handleQueryError,
  };
};
