import React, { useState } from "react";
import Modal from "../../Molecules/Modal";

interface HelpModalProps {
  children: any;
  title: string;
}

const HelpModal = (props: HelpModalProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="w-full px-3">
        <p
          onClick={() => setOpen(true)}
          className="rounded hover:bg-neutral-200 hover:shadow lg:px-2 lg:py-2 px-1 py-2 lg:text-base text-sm w-full text-black font-medium hover:cursor-pointer">
          {props.title}
        </p>
      </div>

      {open && (
        <Modal subject={props.title} onClose={() => setOpen(false)}>
          {props.children}
        </Modal>
      )}
    </>
  );
};

export default HelpModal;
