import { lazy } from "react";
const pages = {
  /* Unprotected */
  Login: lazy(() => import("./UnProtected/Login")),
  Logout: lazy(() => import("./UnProtected/Logout")),
  ResetPassword: lazy(() => import("./UnProtected/ResetPassword")),
  SetPassword: lazy(() => import("./UnProtected/SetPassword")),
  ResendVerificationEmail: lazy(() => import("./UnProtected/ResendVerificationEmail")),
  ConfirmAccount: lazy(() => import("./UnProtected/ConfirmAccount")),
  /* General */
  Dashboard: lazy(() => import("./Dashboard")),
  /* Robots */
  RobotStatus: lazy(() => import("./Robots/RobotStatus")),
  ViewRobots: lazy(() => import("./Robots/ViewRobots")),
  ImageGallery: lazy(() => import("./Robots/ImageGallery")),
  RobotSettings: lazy(() => import("./Robots/RobotSettings")),
  VideoStreaming: lazy(() => import("./Robots/VideoStreaming")),
  RobotGraphs: lazy(() => import("./Robots/RobotGraphs")),
  RobotExportData: lazy(() => import("./Robots/RobotExportData")),
  AddRobot: lazy(() => import("./Robots/AddRobot")),
  ConfirmUserAssociation: lazy(() => import("./Robots/ConfirmUserAssociation")),
  /* ProdCycles */
  ViewProdCycles: lazy(() => import("./ProdCycles/ViewProdCycles")),
  CreateProdCycle: lazy(() => import("./ProdCycles/CreateProdCycle")),
  ViewProdCycle: lazy(() => import("./ProdCycles/ViewProdCycle")),
  /* Farms */
  ViewFarms: lazy(() => import("./Farms/ViewFarms")),
  CreateFarm: lazy(() => import("./Farms/CreateFarm")),
  ViewFarm: lazy(() => import("./Farms/ViewFarm")),
  /* Houses */
  ViewHouse: lazy(() => import("./Houses/ViewHouse")),
  ViewHouses: lazy(() => import("./Houses/ViewHouses")),
  CreateHouse: lazy(() => import("./Houses/CreateHouse")),
  HouseStatus: lazy(() => import("./Houses/HouseStatus")),
  HouseGraphs: lazy(() => import("./Houses/HouseGraphs")),
  /* Alerts */
  ViewAlerts: lazy(() => import("./Alerts/ViewAlerts")),
  /* Users */
  ViewUsers: lazy(() => import("./Users/ViewUsers")),
  ViewUser: lazy(() => import("./Users/ViewUser")),
  CreateUser: lazy(() => import("./Users/CreateUser")),
  ChangePassword: lazy(() => import("./Users/ChangePassword")),
  /* Reports */
  Reports: lazy(() => import("./Reports")),
  /* Admin */
  AdminPanel: lazy(() => import("./Admin/View")),
  CreateRobot: lazy(() => import("./Admin/CreateRobot")),
  PartsTypes: lazy(() => import("./Admin/PartsTypes")),
  CreatePartType: lazy(() => import("./Admin/CreatePartType")),
  PartType: lazy(() => import("./Admin/PartType")),
  RobotParts: lazy(() => import("./Admin/RobotParts")),
  ManufacturingReferences: lazy(() => import("./Admin/ManufacturingReferences")),
  CreateManufacturingReference: lazy(() => import("./Admin/CreateManufacturingReference")),
  RobotObservables: lazy(() => import("./Admin/RobotObservables")),
  /* Documentations */
  Documentation: lazy(() => import("./Documentations/Documentation")),

  /* Error pages */
  ErrorPage: lazy(() => import("./Error/ApiError")),
};

export default pages;
