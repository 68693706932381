/*
NEW DATA PROCESSING ENDPOINTS
*/

import axios from "axios";
import { addDaysToDate, differenceOfDays, getIsoDate, subDaysToDate } from "../DataManagement";

const intervalTime = {
  day: "1d",
  hour3: "3h",
  hour2: "2h",
  hour: "1h",
  min30: "30m",
  min: "15m",
};

export const getJwtToken = () => {
  return localStorage.getItem("jwtToken");
};

export const makeAuthorizedRequest = async (url, signal) => {
  const jwtToken = getJwtToken();
  if (!jwtToken) {
    throw new Error("Token JWT not found");
  }
  const headers = {
    Bearer: jwtToken,
  };
  const { data } = await axios.get(url, { headers, withCredentials: true, signal });
  return data;
};

export const getIsoStartDate = (dateObj) => {
  const date = new Date(dateObj);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const newDate = `${year}-${month}-${day}T00:00:00.00Z`;
  return newDate;
};

export const getHeatMap = async (robotId: string, observable: string, date, siUnit: string, signal: any) => {
  const url = `/dp/heatmap?observableName=${observable}&lte=${date}&robotID=${robotId}&interval=1d&metric=${siUnit}`;
  return makeAuthorizedRequest(url, signal);
};

export const getLinePlot = async (robotId: string, observable: string, interval: string, dateStart, dateEnd, siUnit: string, signal: any) => {
  const start = getIsoStartDate(dateStart);
  const end = differenceOfDays(getIsoDate(new Date()), getIsoDate(dateEnd)) <= 0 ? getIsoDate(dateEnd) : getIsoDate(addDaysToDate(new Date(), 1));

  if (observable === "weight_estimation") {
    const url = `/vem/line-plot?gte=${start}&lte=${end}&interval=1d&robotID=${robotId}`;
    return makeAuthorizedRequest(url, signal);
  } else {
    const url = `/dp/line-plot?observableName=${observable}&gte=${start}&lte=${end}&robotID=${robotId}&interval=${
      intervalTime[interval] ?? "1d"
    }&metric=${siUnit}`;
    return makeAuthorizedRequest(url, signal);
  }
};

export const getWeightAvgLinePlot = async (dateStart, dateEnd, selectedRobots: [any], robots, signal: any) => {
  const start = getIsoStartDate(dateStart);
  const end = differenceOfDays(getIsoDate(new Date()), getIsoDate(dateEnd)) <= 0 ? getIsoDate(dateEnd) : getIsoDate(addDaysToDate(new Date(), 1));

  const cleanRobots = selectedRobots.filter((robot) => robot !== "average");
  let robotsString = cleanRobots.join();
  if (cleanRobots.length <= 1) {
    robotsString = robots.map((robot) => robot.id).join();
  }

  console.log(robotsString);
  const url = `/vem/line-plot?gte=${start}&lte=${end}&interval=1d&robotID=${robotsString}`;
  return makeAuthorizedRequest(url, signal);
};

export const getWeightAvg = async (robots, signal: any) => {
  const start = getIsoStartDate(subDaysToDate(new Date(), 1));
  const end = getIsoDate(addDaysToDate(new Date(), 1));
  const cleanRobots = robots.map((robot) => robot.id);
  let robotsString = cleanRobots.join();
  if (cleanRobots.length <= 1) {
    robotsString = robots.map((robot) => robot.id).join();
  }

  console.log(robotsString);
  const url = `/vem/line-plot?gte=${start}&lte=${end}&interval=1d&robotID=${robotsString}`;
  return makeAuthorizedRequest(url, signal);
};

export const getHourlyMap = async (robotId: string, observable: string, dateStart, dateEnd, siUnit: string, signal: any) => {
  const start = getIsoStartDate(dateStart);
  const end =
    differenceOfDays(getIsoDate(new Date()), getIsoDate(dateEnd)) <= 0
      ? addDaysToDate(getIsoDate(dateEnd), 1).toISOString()
      : addDaysToDate(new Date(), 1).toISOString();

  const url = `/dp/hourly-overview?observableName=${observable}&gte=${start}&lte=${end}&robotID=${robotId}&metric=${siUnit}`;
  return makeAuthorizedRequest(url, signal);
};

export const getYesterdayValues = async (robotId: string, siUnit: string, signal: any) => {
  const url = `/dp/segmentation?robotID=${robotId}&type=yesterday&metric=${siUnit}`;
  return makeAuthorizedRequest(url, signal);
};

export const getLatestValues = async (robotId: string, siUnit: string, signal: any) => {
  const url = `/dp/segmentation?robotID=${robotId}&type=today&metric=${siUnit}`;
  return makeAuthorizedRequest(url, signal);
};

/*
BY ROUNDS
*/

export const getLinePlotByProdCycle = async (
  roundId: string,
  observable: string,
  interval: string,
  dateStart,
  dateEnd,
  siUnit: string,
  signal: any
) => {
  const start = getIsoStartDate(dateStart);
  const end = differenceOfDays(getIsoDate(new Date()), getIsoDate(dateEnd)) <= 0 ? getIsoDate(dateEnd) : getIsoDate(addDaysToDate(new Date(), 1));
  const url = `/dp/round?roundID=${roundId}&observableName=${observable}&gte=${start}&lte=${end}&interval=${
    intervalTime[interval] ?? "1d"
  }&type=line&metric=${siUnit}`;
  return makeAuthorizedRequest(url, signal);
};

export const getHeatMapByProdCycle = async (roundId: string, robotId: string, observable: string, date, siUnit: string, signal: any) => {
  const url = `/dp/round?roundID=${roundId}&robotID=${robotId}&observableName=${observable}&lte=${date}&interval=1d&type=heatmap&metric=${siUnit}`;
  return makeAuthorizedRequest(url, signal);
};

export const getHourlyMapProdCycle = async (roundId: string, observable: string, dateStart, dateEnd, siUnit: string, signal: any) => {
  const start = getIsoStartDate(dateStart);

  const end =
    differenceOfDays(getIsoDate(new Date()), getIsoDate(dateEnd)) <= 0
      ? addDaysToDate(getIsoDate(dateEnd), 1).toISOString()
      : addDaysToDate(new Date(), 1).toISOString();

  const url = `/dp/round?roundID=${roundId}&observableName=${observable}&gte=${start}&lte=${end}&interval=1d&type=hourly&metric=${siUnit}`;
  return makeAuthorizedRequest(url, signal);
};

export const getYesterdayValuesProdCycle = async (roundId: string, siUnit: string, signal: any) => {
  const url = `/dp/round?roundID=${roundId}&type=yesterday&metric=${siUnit}`;
  return makeAuthorizedRequest(url, signal);
};

export const getLatestValuesProdCycle = async (roundId: string, siUnit: string, signal: any) => {
  const url = `/dp/round?roundID=${roundId}&type=today&metric=${siUnit}`;
  return makeAuthorizedRequest(url, signal);
};

export const getLatestValuesWeight = async (robotId: string, signal: any) => {
  const url = `/vem/segmentation?robotID=${robotId}&type=today`;
  return makeAuthorizedRequest(url, signal);
};

export const getYesterdayValuesWeight = async (robotId: string, signal: any) => {
  const url = `/vem/segmentation?robotID=${robotId}&type=yesterday`;
  return makeAuthorizedRequest(url, signal);
};

export const dataExportFetch = (key: string, observable: string, event: string, group: string, prodCycle: string) => {
  return `/report/export?observable=${observable}&event=${event}&group=${group}&prodcycle=${prodCycle}&key=${key}`;
};

/*
ALERTS
*/

export const getAlertConfig = async (houseId: string, userId: string, signal: any) => {
  const url = `/alert/list?house_id=${houseId}`;
  return makeAuthorizedRequest(url, signal);
};

export const createAlert = () => {
  return `/alert/create`;
};
export const updateAlert = () => {
  return `/alert/update`;
};

export const deleteAlert = (houseId, userId, observable) => {
  return `/alert/delete?house_id=${houseId}&observable=${observable}`;
};

export const getAlertMessages = async (userId: string, offset: number, limit: number, signal: any) => {
  const url = `/alert/list/messages?from=${offset}&size=${limit}`;
  return makeAuthorizedRequest(url, signal);
};
export const getHouseAlertMessages = async (userId: string, houseId: string, offset: number, limit: number, signal: any) => {
  const url = `/alert/list/messages?house_id=${houseId}&from=${offset}&size=${limit}`;
  return makeAuthorizedRequest(url, signal);
};
