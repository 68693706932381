import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import listenForOutsideClicks from "../../../util/Modals/listenForOutsideClicks";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
interface UserProfileNavInterface {
  name: String;
  email: String;
  id: String;
}

function initials(name: string) {
  if (name !== "" && name !== null) {
    let initials: any = "";
    initials = name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
    if (initials.length === 1) {
      initials += name[1];
    }
    return initials;
  }
  return "F";
}

const UserProfileNav = (props: UserProfileNavInterface) => {
  const { t } = useTranslation("translation");
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const isTabletDevice = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setIsOpen));

  return (
    <div ref={menuRef} className="flex-none relative lg:w-fit">
      {!(isTabletDevice || isMobileDevice) && (
        <div onClick={toggle} className="bg-neutral-150 hover:bg-neutral-200 rounded-full py-2.5 h-12 w-12 cursor-pointer">
          <p className="my-1 lg:text-sm text-lg text-black font-semibold uppercase text-center">{initials(props.name as string)}</p>
        </div>
      )}
      {(isTabletDevice || isMobileDevice) && (
        <p onClick={toggle} className="font-medium text-white text-xl flex">
          {t("Profile")} <span className="material-symbols-outlined mt-0.5 ml-3">expand_more</span>
        </p>
      )}
      <div
        className={`${
          !isOpen && "hidden"
        } z-10 w-60 h-auto bg-white rounded drop-shadow border absolute md:top-14 top-10 md:right-0 md:left-auto left-0 text-sm`}>
        <div className="p-2 w-full grid grid-cols-1">
          <RouterLink className="rounded p-2 hover:bg-neutral-200 w-full" to={`/user/${props.id}`}>
            {t("Profile")}
          </RouterLink>
          <RouterLink className="rounded p-2 hover:bg-neutral-200 w-full" to={`/user/${props.id}/change-password`}>
            {t("Change Password")}
          </RouterLink>
        </div>
        <div className="border-t p-2 w-full grid grid-cols-1">
          <RouterLink className="rounded p-2 hover:bg-neutral-200 w-full" to="/user/list">
            {t("View users created")}
          </RouterLink>
        </div>
        <div className="border-t p-2 w-full grid grid-cols-1">
          <RouterLink className="rounded p-2 hover:bg-neutral-200 w-full" to="/docs">
            {t("Export documentation")}
          </RouterLink>
        </div>

        <div className="border-t p-2 w-full grid grid-cols-1">
          <RouterLink className="rounded p-2 hover:bg-neutral-200 w-full" to="/logout">
            {t("Logout")}
          </RouterLink>
        </div>
      </div>
    </div>
  );
};

export default UserProfileNav;
