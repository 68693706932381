import React from "react";
import FormatedDate from "../../Atoms/Dates/FormatedDate";
import { useNavigate } from "react-router-dom";

interface AlertCardProps {
  date: any;

  message: string;
  house: any;
  onClose: any;
}

const AlertCard = (props: AlertCardProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    props.onClose();
    navigate(`/house/${props.house.id}/status`);
  };
  return (
    <>
      <div className="border p-2 rounded bg-white h-fit hover:drop-shadow cursor-pointer" onClick={() => handleClick()}>
        <p className="text-sm text-black font-medium">
          {props.house.name} -{" "}
          <span className="text-xs mt-0.5 text-neutral-500">
            <FormatedDate type="date" date={new Date(props.date)} /> <FormatedDate type="time" date={new Date(props.date)} />
          </span>
        </p>
        <p className="text-xs text-neutral-700 mt-1">{props.message}</p>
      </div>
    </>
  );
};

export default AlertCard;
