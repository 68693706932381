import axios from "axios";

export const getGraphScale = async (observable: string, signal: any) => {
  const url = `/api/v2/users/get-graph-scale?observable=${observable}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getGraphFilters = async (signal: any) => {
  const url = `/api/v2/users/get-graph-filters`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getUser = async (userId: string, signal: any) => {
  const url = `/api/v2/users/return-user?userId=${userId}`;
  const { data } = await axios.get(url, { signal });
  return data;
};
export const getUsers = (page, limit, search, status = 0, signal: any) => {
  const realSearch = search === "" ? "false" : search;
  const offset = page * limit;
  const url = `/api/v2/users/get-users-created?limit=${limit}&offset=${offset}&search=${realSearch}&robot=${false}&farm=${false}&house=${false}&range=${false}&status=${status}`;
  const req = axios.get(url, { signal });
  return req;
};
export const deteGraphFilter = (id) => {
  return `/api/v2/users/delete-graph-filter?id=${id}`;
};
export const createGraphFilter = () => {
  return `/api/v2/users/create-graph-filter`;
};
export const updateGraphScale = () => {
  return `/api/v2/users/update-graph-scale`;
};
export const createGraphScale = () => {
  return `/api/v2/users/create-graph-scale`;
};
export const confirmAccount = (expires, userId, signature) => {
  return `/api/v2/users/confirm-account?expires=${expires}&id=${userId}&signature=${signature}`;
};
export const registerUrl = () => {
  return `/api/v2/users/register`;
};
export const checkIfUserExist = () => {
  return `/api/v2/users/check-if-user-exists`;
};
export const resendVerificationEmail = () => {
  return `/api/v2/users/resend-verification-email`;
};
export const setInactive = (id) => {
  return `/api/v2/users/set-inactive?userId=${id}`;
};
export const setActive = (id) => {
  return `/api/v2/users/set-active?userId=${id}`;
};
export const addUser = () => {
  return `/api/v2/users/add-user`;
};
export const updateUser = (userId) => {
  return `/api/v2/users/update-user?userId=${userId}`;
};
export const resetPassword = () => {
  return `/api/v2/users/reset-password`;
};
export const setPassword = (search) => {
  return `/api/v2/users/set-password?${search}`;
};
export const updatePassword = () => {
  return `/api/v2/users/update-password`;
};
export const validateOldPassword = () => {
  return `/api/v2/users/validate-password`;
};
export const fetchUserData = () => {
  const url = `/api/v2/users/get-user-data`;
  const req = axios.get(url, { headers: { "content-type": "application/x-www-form-urlencoded" } });
  return req;
};
export const fetchUserLoginStatus = () => {
  const url = `/api/v2/users/check-if-user-session-is-active`;
  const req = axios.get(url, { headers: { "content-type": "application/x-www-form-urlencoded" } });
  return req;
};
export const editData = (array, type, data) => {
  // For now, only used to increate total number of entities on create and edit user name
  if (["robots", "users", "houses", "farms", "alerts", "rounds", "diaries"].indexOf(type) >= 0) {
    array["total"] = array["total"] + 1;
  } else {
    array["name"] = data["shortName"];
    array["chartScale"] = data["chartScale"];
  }
  return array;
};

export const logout = () => {
  return `/api/v2/auth/logout`;
};
export const login = () => {
  return `/api/v2/auth/login`;
};
export const getUserData = () => {
  return `/api/v2/users/get-user-data`;
};
