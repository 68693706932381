import { actionTypes } from "../types";
import { Action } from "redux";

export interface InitUserAction extends Action {
  payload?: any;
  type: actionTypes;
}

export const loggedIn: () => InitUserAction = () => {
  return {
    type: actionTypes.LOGGED_IN,
  };
};

export const loggedOut: () => InitUserAction = () => {
  return {
    type: actionTypes.LOGGED_OUT,
  };
};

export const getUserLoginStatus: () => InitUserAction = () => {
  return {
    type: actionTypes.GET_USER_LOGIN_STATUS,
  };
};

export const refetchUserData: () => InitUserAction = () => {
  return {
    type: actionTypes.REFETCH_USER_DATA,
  };
};

export const setUserLoginStatus: (res: any) => InitUserAction = (res: any) => {
  return {
    type: actionTypes.SET_USER_LOGIN_STATUS,
    payload: {
      response: res.data,
    },
  };
};

export const setUserData: (res: any) => InitUserAction = (res: any) => {
  return {
    type: actionTypes.SET_USER_DATA,
    payload: {
      response: res.data,
    },
  };
};

export const updateUserData = (data: any, type: any) => {
  return {
    type: actionTypes.UPDATE_USER_DATA,
    payload: {
      data,
      type,
    },
  };
};

export const setFarmDashboardPicked: (farmId: string) => InitUserAction = (farmId: string) => {
  return {
    type: actionTypes.SET_FARM_DASHBOARD_PICKED,
    payload: {
      farmId: farmId,
    },
  };
};

export const setRedirectUrl: (url: string) => InitUserAction = (url: string) => {
  return {
    type: actionTypes.SET_REDIRECT_URL,
    payload: {
      url: url,
    },
  };
};

export const setDashboardFilters: (filters: any) => InitUserAction = (filters: any) => {
  return {
    type: actionTypes.SET_DASHBOARD_FILTERS,
    payload: {
      filters: filters,
    },
  };
};
