import React from "react";
import { useTranslation } from "react-i18next";

interface DateProps {
  date: any;
  type?: string;
}
const FormatedDate = (props: DateProps) => {
  const { i18n } = useTranslation("translation");
  const language = i18n.language.replace("_", "-");

  if (props.type === "date") {
    return <>{props.date.toLocaleDateString(language)}</>;
  } else if (props.type === "time") {
    return <>{props.date.toLocaleTimeString(language)}</>;
  }
  return props.date.toLocaleString(language);
};

export default FormatedDate;
