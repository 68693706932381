import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import UserProfileNav from "../../Molecules/UserProfileNav";
import { useMediaQuery } from "react-responsive";
import Footer from "../Footer";
import AlertsModal from "../AlertsModal";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  userData: {
    identity: any;
    dashboard: any;
    robots: any;
  };
  houses: number;
  farms: number;
  robots: number;
  alerts: number;
}

const Header = (props: HeaderProps) => {
  const { t } = useTranslation("translation");
  const [navActive, setNavActive] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [alertsOpen, setAlertsOpen] = useState(false);
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const isTabletDevice = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });

  const handleClick = () => {
    setNavActive(!navActive);
  };

  const handleProfileClick = () => {
    setProfileOpen(!profileOpen);
  };

  const page = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="bg-white w-full z-50   md:relative fixed top-0">
        <div className="flex max-w-body px-4 mx-auto gap-5 ">
          <div className="w-full flex gap-2.5">
            <RouterLink to="/home" className="mt-2 mb-4">
              <img src="/img/scout-logo.png" alt="SCOUT" className="lg:mt-7 md:mt-7 lg:h-10 md:h-10 h-8 mt-2.5" />
            </RouterLink>
          </div>
          <div
            onClick={() => setAlertsOpen(true)}
            className="flex md:mt-0 mt-4 mb-3 px-0.5 pt-0.5 gap-1 hover:text-black hover:bg-neutral-100 md:hidden rounded">
            {props.alerts > 0 && (
              <p className={`${props.alerts > 0 && "bg-rose-500"} rounded-full w-6 h-6 text-white text-center text-base mt-1`}>{props.alerts}</p>
            )}

            <span className="material-symbols-outlined font-light text-neutral-400 h-fit text-2xl">notifications</span>
          </div>

          {(isTabletDevice || isMobileDevice) && alertsOpen && <AlertsModal isOpen={alertsOpen} setIsOpen={setAlertsOpen} />}
          {(isTabletDevice || isMobileDevice) && (
            <>
              <div
                className={`${
                  navActive ? " right-0" : "-right-full"
                } top-0 sidebar-gradient fixed w-screen h-screen z-50 transition-all px-5 py-2 flex flex-col`}>
                <div className="flex">
                  <div className="w-full flex gap-2.5">
                    <img src="/img/scout-logo-white.png" alt="SCOUT" className="h-8 mt-3" />
                  </div>
                  <span className="material-symbols-outlined text-white font-normal text-2xl h-fit mt-3" onClick={handleClick}>
                    close
                  </span>
                </div>
                <li className="list-none mt-10 h-full">
                  <ul className="mb-5">
                    <RouterLink to="/home" className="font-medium text-white text-xl" onClick={handleClick}>
                      {t("Home")}
                    </RouterLink>
                  </ul>
                  <ul className="mb-5">
                    <RouterLink to="/cycle/list" className="font-medium text-white text-xl" onClick={handleClick}>
                      {t("Production Cycles")}
                    </RouterLink>
                  </ul>
                  <ul className="mb-5">
                    <RouterLink to="/reports" className="font-medium text-white text-xl" onClick={handleClick}>
                      {t("Reports")}
                    </RouterLink>
                  </ul>

                  <ul className={`${profileOpen ? "h-60" : " h-10"} overflow-hidden transition-all`}>
                    <p className="font-medium text-white text-xl flex" onClick={handleProfileClick}>
                      {t("Profile")}
                      <span className={`${profileOpen && "rotate-180"} transition-all material-symbols-outlined mt-1 ml-2 `}>expand_more</span>
                    </p>

                    <li className="pl-5 mt-3">
                      <ul className="mb-3">
                        <RouterLink to={`/user/${props.userData.identity.id}`} className="font-medium text-white text-xl" onClick={handleClick}>
                          {t("Profile")}
                        </RouterLink>
                      </ul>
                      <ul className="mb-3">
                        <RouterLink
                          to={`/user/${props.userData.identity.id}/change-password`}
                          className="font-medium text-white text-xl"
                          onClick={handleClick}>
                          {t("Change Password")}
                        </RouterLink>
                      </ul>
                      <ul className="mb-3">
                        <RouterLink to="/user/list" className="font-medium text-white text-xl" onClick={handleClick}>
                          {t("View users created")}
                        </RouterLink>
                      </ul>

                      <ul className="mb-3">
                        <RouterLink to="/logout" className="font-medium text-white text-xl" onClick={handleClick}>
                          {t("Logout")}
                        </RouterLink>
                      </ul>
                    </li>
                  </ul>
                </li>
                <Footer></Footer>
              </div>

              {isMobileDevice && (
                <div className="fixed bottom-0 left-0 sidebar-gradient w-full z-40">
                  <div className="border-x border-neutral-150 divide-x flex text-white font-medium w-fit mx-auto">
                    <RouterLink to="/farm/list" className="flex px-4 gap-3 py-3 hover:bg-rose-700 hover:text-white">
                      <p>{props.farms}</p>
                      <span className="material-symbols-outlined font-light">agriculture</span>
                    </RouterLink>
                    <RouterLink to="/house/list" className="flex px-4 gap-3 py-3 hover:bg-rose-700 hover:text-white">
                      <p>{props.houses}</p>
                      <span className="material-symbols-outlined font-light">home</span>
                    </RouterLink>
                    <RouterLink to="/robot/list" className="flex px-4 gap-3 py-3 hover:bg-rose-700 hover:text-white">
                      <p>{props.robots}</p>
                      <span className="material-symbols-outlined font-light">smart_toy</span>
                    </RouterLink>
                  </div>
                </div>
              )}
            </>
          )}
          {!isMobileDevice && (
            <div className="py-9 flex gap-5">
              <div className="gap-7 py-2.5 w-max lg:flex hidden">
                <RouterLink
                  to="/home"
                  className={`${page === "home" ? "text-black" : "text-neutral-400"} font-medium text-base w-max hover:text-black`}>
                  {t("Home")}
                </RouterLink>
                <RouterLink
                  to="/cycle/list"
                  className={`${page === "cycle" ? "text-black" : "text-neutral-400"} font-medium text-base w-max hover:text-black`}>
                  {t("Production Cycles")}
                </RouterLink>
                <RouterLink
                  to="/reports"
                  className={`${page === "reports" ? "text-black" : "text-neutral-400"} font-medium text-base w-max hover:text-black`}>
                  {t("Reports")}
                </RouterLink>
              </div>
              <div className="border rounded border-neutral-150 divide-x flex font-medium">
                <RouterLink
                  to="/farm/list"
                  className={`${
                    page === "farm" ? "bg-neutral-100 text-black" : "text-neutral-400 bg-white"
                  } flex px-4 py-2.5 gap-3 hover:text-black hover:bg-neutral-100`}
                  title={t("Farms") as string}>
                  <p>{props.farms}</p>
                  <span className="material-symbols-outlined font-light">agriculture</span>
                </RouterLink>
                <RouterLink
                  to="/house/list"
                  className={`${
                    page === "house" ? "bg-neutral-100 text-black" : "text-neutral-400 bg-white"
                  } flex px-4 py-2.5 gap-3 hover:text-black hover:bg-neutral-100`}
                  title={t("Houses") as string}>
                  <p>{props.houses}</p>
                  <span className="material-symbols-outlined font-light">home</span>
                </RouterLink>
                <RouterLink
                  to="/robot/list"
                  className={`${
                    page === "robot" ? "bg-neutral-100 text-black" : "text-neutral-400 bg-white"
                  } flex px-4 py-2.5 gap-3 hover:text-black hover:bg-neutral-100`}
                  title={t("Robots") as string}>
                  <p>{props.robots}</p>
                  <span className="material-symbols-outlined font-light">smart_toy</span>
                </RouterLink>
                <div
                  className={`${
                    page === "alert" ? "bg-neutral-100 text-black" : "text-neutral-400 bg-white"
                  } flex px-4 py-2.5 gap-1 hover:text-black hover:bg-neutral-100 relative`}
                  title={t("Alerts") as string}>
                  <span onClick={() => setAlertsOpen(true)} className="material-symbols-outlined font-light  cursor-pointer">
                    notifications
                  </span>
                  {alertsOpen && <AlertsModal isOpen={alertsOpen} setIsOpen={setAlertsOpen} />}
                </div>
              </div>
              <div className="lg:block hidden">
                <UserProfileNav
                  name={props.userData.identity.name}
                  email={props.userData.identity.email}
                  id={props.userData.identity.id}></UserProfileNav>
              </div>
            </div>
          )}

          <div className={`${isMobileDevice ? "mt-6" : "sm:mt-12 mt-6"} menu relative w-7 h-5  lg:hidden block cursor-pointer`} onClick={handleClick}>
            <div className="absolute mt-0 w-5 h-0.5 bg-neutral-400 rounded-full"></div>
            <div className="absolute mt-2 w-5 h-0.5 bg-neutral-400 rounded-full"></div>
            <div className="absolute mt-4 w-5 h-0.5 bg-neutral-400 rounded-full"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
